import { updateIntl } from 'react-intl-redux'

function loadLocale (state, action, locale) {
  switch(locale) {
    case 'fr':
    case 'en':
      action.asyncDispatch(updateIntl({
        locale:locale, 
        messages:state[locale]
      }));
      break;
    default:
      console.error('Locale unrecognized: ',action.locale)
  }
  return state;
}

function localesReducer(state = {}, action) {
  switch (action.type) {
    case 'SET_LOCALE':
      localStorage.setItem('locale', action.locale);
      return loadLocale(state, action, action.locale)
    case 'LOAD_STORED_DATA': 
      const initialLocale =  localStorage.getItem('locale')
      if(initialLocale)
        return loadLocale(state, action, initialLocale)
      return state;
    case 'UPDATE_LOCALES':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default localesReducer;
