import React, { Component } from 'react';
import { Loading } from 'arwes';

class LoadingComponent extends Component {
  render() {
    return (
      <div style={{height:'100vh'}}><Loading animate full/></div>
    )
  }
}
export default LoadingComponent;