import { setContentPanel, triggerCustomEvent } from 'discapecommon/actions/index'
import {missilGouvSetStatus, missilGouvSuccess, upMissilGouvSetStatus, upMissilGouvEntries,
  upTelemetryNewIndex} from 'discapecommon/apps/missilGouv/missilGouvActions'
import {playAudio, stopAudio} from 'discapecommon/actions/mediaActions'
import {channels} from 'discapecommon/consts/channels';
import playerInfos from '../../core/playerInfos'

const words = ['EPSILON', 'GAMMA', 'DELTA', 'INFORMATION', 
  'BRAVO', 'TANGO', 'FOXTROT', 'NOVEMBER', 'ZULU', 'GOLF', 'QUEBEC', 
  'BLOCUS', 'INVESTIGATION', 'EXPLOSION', 'SUSPECT', 'MISSILE', 'MAYDAY', 'INFILTRATION', 
  'AGENT', 'ALLIANCE', 'ASSASSIN', 'CODE', 'DANGER', 'INVISIBLE', 'DESTRUCTION', 
  'EXTERMINATION', 'GADGET', 'IMPERCEPTIBLE', 'INNOCENT', 'INTELLIGENCE',
  'INTRODUCTION', 'CAMOUFLAGE', 'IMITATION', 'MENACE', 'OBSERVATION', 'PROJECTILE',
  'SATELLITE', 'VIGILANT',  'ROGER'
]

const defaultState = {
  entries:[],
  phase1Status: 'NORMAL',
  phase2Status: 'DISABLED',
  consoleMode: 'NORMAL',
  telemetryWord: 'EPSILON',
  telemetryNextWord: 'GAMMA',
  telemetryPoints:0,
  telemetryIndex:0,
  role: 'player1',
  countDownMode: 'NORMAL',
}

const rightSequence = [3,5,2,4,6,1]
/* RIGHT SEQUENCE
3: Provoquer les actions barriere
5: Equilibrer le niveau de Kérosène
2: Baliser les coordonnées extérieures
4: Répéter le code de dislocation
6: Stabiliser les détecteurs
1: Transfert données exploitation
*/

const isSequenceValid = (sequence) => {
  return rightSequence.equals(sequence);
}

const applyNewStatus = (state, action, status) => {
  if(status == 'PHASE1_'+state.phase1Status || status == 'PHASE2_'+state.phase2Status)
    return state;

  switch(status) {
    case 'PHASE1_FAILED': 
      if(state.phase1Status == 'SUCCESS' || state.phase1Status == 'SUPER_FAST') 
        return state;
     return {...state, phase1Status: 'FAILED', entries: []}
    case 'PHASE1_WRONG': return {...state, phase1Status: 'WRONG', entries: []}
    case 'READY':
    case 'PHASE1_NORMAL': 
      return {...state, phase1Status: 'NORMAL', entries: []}
    case 'PHASE1_SUPER_FAST': return {...state, countDownMode: 'SUPER_FAST'}
    case 'PHASE1_SUCCESS': 
      if(state.phase1Status == 'SUCCESS') 
        return state;
      action.asyncDispatch(missilGouvSuccess());
      return {...state, phase1Status: 'SUCCESS', countDownMode:'SUPER_FAST'}
    case 'PHASE2_NORMAL': return {...state, phase2Status: 'NORMAL'}
    case 'PHASE2_WILL_SUCCEED': 
      action.asyncDispatch(playAudio(1002))
      return {...state, phase2Status: 'PHASE2_WILL_SUCCEED'}
    case 'PHASE2_SUCCESS': return {...state, phase2Status: 'SUCCESS'}
    case 'PHASE2_FAILED': return {...state, phase2Status: 'FAILED'}
    default: console.error('Unrecognized status: ',status)
  }
}

const loadStage20 = (state, action) => {
  return {...state, phase1Status:'SUCCESS', phase2Status:'NORMAL', countDownMode:'NORMAL'}
}

const missilGouv = (state = defaultState, action) => {
  switch(action.type) {
    case 'MISSIL_GOUV_BUTTON_CLICK':
      var entries = [...state.entries,action.id];
      action.asyncDispatch(upMissilGouvEntries(entries));
      if(entries.length >= 6) {
        if(isSequenceValid(entries)) {
          action.asyncDispatch(missilGouvSetStatus('PHASE1_SUCCESS'));
          action.asyncDispatch(playAudio(1007, 30, channels.SFX));
          action.asyncDispatch(playAudio(1012, 100, channels.SFX));
          action.asyncDispatch(stopAudio(4, channels.MUSIC));
          action.asyncDispatch(playAudio(1013, 100, channels.MUSIC));
        }
        else {
          action.asyncDispatch(missilGouvSetStatus('PHASE1_WRONG'));
          action.asyncDispatch(playAudio(1006, 100, channels.SFX));
          setTimeout(function() {
            action.asyncDispatch(missilGouvSetStatus('PHASE1_NORMAL'));
          }, 1200);
        }
      }
      return {...state, entries}
    case 'DOWN_MISSIL_GOUV_ENTRIES':
      if(state.entries.equals(action.entries))
        return state;
      else
        return {...state, entries: action.entries}

    case 'COUNT_DOWN_TIMEOUT':
      switch(action.id) {
        case 0:
          // Lancement du missile
          return loadStage20(state, action);
      }
      return state;
    case 'MISSIL_GOUV_SET_STATUS':
      action.asyncDispatch(upMissilGouvSetStatus(action.status));
      return applyNewStatus(state, action, action.status);
    case 'DOWN_MISSIL_GOUV_SET_STATUS':
      return applyNewStatus(state, action, action.status);
    case 'MISSIL_GOUV_EMERGENCY_CLICK':
      return {...state, consoleMode:'EMERGENCY'}
    case 'TRIGGER_CUSTOM_EVENT':
      switch(action.key) {
        case 'TREASON_STAGE_20':
          return loadStage20(state, action);
      }
      return state;
    case 'TELEMETRY_SUBMIT':
      // console.log('index ', newIndex, words[newIndex])
      if(action.data.trim().toUpperCase() == words[state.telemetryIndex]) {
        var newIndex = (state.telemetryIndex + 1) % words.length;
        var newPoints = state.telemetryPoints + 1;
        action.asyncDispatch(upTelemetryNewIndex(newIndex, newPoints));
        action.asyncDispatch(playAudio(1008, 100, channels.SFX));
        return {...state, 
          telemetryIndex:newIndex, 
          telemetryPoints:newPoints, 
          telemetryWord:words[newIndex],
          telemetryLastValid:true,
          telemetryNextWord:words[(newIndex+1)%words.length],
        }
      }
      else {
        action.asyncDispatch(playAudio(1006, 100, channels.SFX));
        // action.asyncDispatch(upTelemetryNewIndex(state.telemetryIndex, state.telemetryPoints));
        return {...state, 
          telemetryIndex:state.telemetryIndex, 
          telemetryWord:words[state.telemetryIndex],
          telemetryLastValid:false,
          telemetryNextWord:words[(state.telemetryIndex + 1) % words.length],     
        }
      }
    case 'DOWN_TELEMETRY_NEW_INDEX':
      if(action.index == state.telemetryIndex 
        && action.formPlayerNumber == state.formPlayerNumber)
        return state;
      if(action.points > state.telemetryPoints)
        action.asyncDispatch(playAudio(1008, 100, channels.SFX));
      else
        action.asyncDispatch(playAudio(1006, 100, channels.SFX));
      return {...state, 
        telemetryIndex:action.index, 
        telemetryPoints:action.points,
        telemetryWord: words[action.index],
        telemetryLastValid:action.points > state.telemetryPoints,
        telemetryNextWord: words[(action.index+1) % words.length],
        formPlayerNumber: action.formPlayerNumber,
      }
    case 'TELEMETRY_SUCCESS':
      action.asyncDispatch(missilGouvSetStatus('PHASE2_WILL_SUCCEED'));
      setTimeout(() => action.asyncDispatch(missilGouvSetStatus('PHASE2_SUCCESS')), 2600)
      return state;
    case 'PUSH_SESSION_STATE':
      // console.log('state ',action.state?.appsState?.missilGouv?.status)
      const actionState = action.state?.appsState?.missilGouv;
      if(!actionState)
        return state;
      var newState = {...state};
      if(actionState.status) {
        newState = applyNewStatus(state, action, actionState.status)
        newState.consoleMode = 'NORMAL';
      }
      if(actionState.entries) {
        newState.entries = actionState.entries;
      }
      if(actionState.index) {
        newState.telemetryIndex = actionState.index;
      }
      if(actionState.formPlayerNumber) {
        newState.formPlayerNumber = actionState.formPlayerNumber;
      }
      return newState;
    default:
      return state;
  }
}

export default missilGouv;