import jmx from '../../assets/images/jmx.jpg'
import femme1 from '../../assets/missions/coldGame/images/Femme1Small.jpg'
import femme2 from '../../assets/missions/coldGame/images/femme2.jpg'
import jose from '../../assets/missions/coldGame/images/JosePaquin.jpg'
import man1 from '../../assets/missions/coldGame/images/Man1.jpg'
import sergeant from '../../assets/missions/treason/images/Vilamo.jpg'
import discapeConcept from '../../assets/images/DiscapeConcept800.jpg'

export default [
  {
    key:'JMX',
    name: 'datasheet.jmx.name', 
    image: jmx,
    imageTitle: 'JMX',
    content: [
      {type:'h3', localeKey: 'datasheet.template1.descriptionTitle'},
      {type:'p', localeKey: 'datasheet.jmx.descriptionContent'},
      {type:'h3', localeKey: 'datasheet.jmx.useTitle'},
      {type:'p', localeKey:`datasheet.jmx.useContent`,},
      {type:'h3', localeKey:'datasheet.jmx.detectionTitle'},
      {type:'p', localeKey: 'datasheet.jmx.detectionContent'},
    ]
  },
  {
    key:'Jose',
    name: 'datasheet.jose.name',
    image: jose,
    imageTitle: 'José Paquin',
    content: [
      {type:'h3', localeKey: 'datasheet.template1.descriptionTitle'},
      {type:'p', localeKey: `datasheet.jose.descriptionContent`},
      {type:'h3', localeKey: 'datasheet.template1.behaviorTitle'},
      {type:'p', localeKey:`datasheet.jose.behaviorContent`},
      {type:'h3', localeKey:'datasheet.template1.conclusionTitle'},
      {type:'p', localeKey: `datasheet.jose.conclusionContent`,
      }
    ]
  },
  {
    key:'Judith',
    name: 'datasheet.judith.name',
    image: femme1,
    imageTitle: 'Judith Rivoli',
    content: [
      {type:'h3', localeKey: 'datasheet.template1.descriptionTitle'},
      {type:'p', localeKey: `datasheet.judith.descriptionContent`},
      {type:'h3', localeKey: 'datasheet.template1.behaviorTitle'},
      {type:'p', localeKey: `datasheet.judith.behaviorContent`},
      {type:'h3', localeKey:'datasheet.template1.conclusionTitle'},
      {type:'p', localeKey: `datasheet.judith.conclusionContent`},
    ]
  },
  {
    key:'Nils',
    name: 'datasheet.nils.name',
    image: man1,
    imageTitle: 'Nils Brown',
    content: [
      {type:'h3', localeKey: 'datasheet.template1.descriptionTitle'},
      {type:'p', localeKey: `datasheet.nils.descriptionContent`},
      {type:'h3', localeKey: 'datasheet.template1.behaviorTitle'},
      {type:'p', localeKey: `datasheet.nils.behaviorContent`},
      {type:'h3', localeKey:'datasheet.template1.conclusionTitle'},
      {type:'p', localeKey: `datasheet.nils.conclusionContent`},
    ]
  },
  {
    key:'Ariane',
    name: 'datasheet.ariane.name',
    image: femme2,
    imageTitle: 'Ariane Estapolo',
    content: [
      {type:'h3', localeKey: 'datasheet.template1.descriptionTitle'},
      {type:'p',  localeKey: `datasheet.ariane.descriptionContent` },
      {type:'h3', localeKey: 'datasheet.template1.behaviorTitle'},
      {type:'p',  localeKey: `datasheet.ariane.behaviorContent`},
      {type:'h3', localeKey: 'datasheet.template1.conclusionTitle'},
      {type:'p',  localeKey: `datasheet.ariane.conclusionContent`}
    ]
  },
  {
    key:'Vilamo',
    name: 'datasheet.vilamo.name',
    image: sergeant,
    imageTitle: 'Eric Vilamo',
    activity: 0,
    content: [
      {type:'h3', localeKey: 'datasheet.template1.descriptionTitle'},
      {type:'p', localeKey: `datasheet.vilamo.descriptionContent`},
      {type:'h3', localeKey: 'datasheet.template1.behaviorTitle'},
      {type:'p', localeKey:`datasheet.vilamo.behaviorContent`},
      {type:'h3', localeKey:'datasheet.template1.conclusionTitle'},
      {type:'p', localeKey: `datasheet.vilamo.conclusionContent`}
    ]
  },
  {
    key:'PaquinCrypted',
    name: 'datasheet.joseMessage.name',
    activity: 1,
    content: [
      {type:'h3', localeKey: 'datasheet.joseMessage.messageTitle'},
      {type:'p', localeKey: `datasheet.joseMessage.messageContent`}
    ]
  },
  {
    key:'discapeAbout',
    name: 'datasheet.discapeAbout.name',
    image: discapeConcept,
    activity: -1,
    content: [
      {type:'h3', localeKey: 'datasheet.discapeAbout.messageTitle'},
      {type:'p', localeKey: `datasheet.discapeAbout.messageContent`}
    ]
  },
  {
    key:'discapeFAQ',
    name: 'datasheet.discapeFAQ.name',
    activity: -1,
    content: [
      {type:'h3', localeKey: 'datasheet.discapeFAQ.q1Title'},
      {type:'p', localeKey: `datasheet.discapeFAQ.q1Content`},
      {type:'h3', localeKey: 'datasheet.discapeFAQ.q2Title'},
      {type:'p', localeKey: `datasheet.discapeFAQ.q2Content`},
      {type:'h3', localeKey: 'datasheet.discapeFAQ.q3Title'},
      {type:'p', localeKey: `datasheet.discapeFAQ.q3Content`},
    ]
  },
]