/*
 * MediaPlayer - Client Only
 */
import {openFolder, goHome, setContentPanel} from 'discapecommon/actions/index'
import {openEnigma} from 'discapecommon/actions/enigmaActions'
import {openApp} from 'discapecommon/actions/appActions'
import {viewMission} from '../actions/missionActions'
import {log} from '../utils'
import SIDE_MENU from '../consts/sideMenu'
// Default state defined in consts


const sideMenu = (state = SIDE_MENU.default, action) => {
  switch (action.type) {
    case 'OPEN_LINK':
      // console.log('state ',state)
      const link = state.find(({id}) => id == action.id);
      if(link) {
        // console.log('link ', link)
        switch(link.refType) {
          case 'ENIGMA':
            action.asyncDispatch(openEnigma(link.refId));
            break;
          case 'FOLDER':
            action.asyncDispatch(openFolder(link.refId));
            break;
          case 'APP':
            action.asyncDispatch(openApp(link.refId));
            break;
          case 'MISSION':
            action.asyncDispatch(viewMission(link.refId));
            break;
          case 'CORE':
            switch(link.refId) {
              case 'GO_HOME':
                action.asyncDispatch(goHome());
                break;
              case 'HINT':
                action.asyncDispatch(setContentPanel('HINT'));
                break;
            }
            break;
          default:
            console.error('Unrecognized link.refType: ',link)
        }
      }
      return state;
    case 'CLT_LOAD_MISSION':
      if(SIDE_MENU[action.missionKey])
        return SIDE_MENU[action.missionKey]
      else {
        console.error('Unrecognized missionKey')
        return state;
      }
    case 'CLT_CHECK_LEAVE_SESSION':
      return SIDE_MENU.default;
    case 'SET_LINK_STATUS':
      return state.map(link => {
        if(link.id == action.id) {
          return {...link, status: action.status};
        }
        return link;
      })
      break;
    case 'SET_LINKS_STATUS':
      return state.map(link => {
        if(action.ids.includes(link.id)) {
          return {...link, status: action.status};
        }
        return link;
      })
      break;
    default:
      return state
  }
}

export default sideMenu
