// MENUS
export default {
  default: [    
    {
      id: 0,
      order:1,
      type:'LINK',
      // name:'Tableau de bord',
      refType:'FOLDER',
      refId:null,
      status:'DEFAULT'
    },    
    {
      id: 1,
      order:1,
      type:'DIVIDER',
      // name:'MISSIONS',
    },
    {
      id: 2,
      order:2,
      type:'LINK',
      // name:'Une trahison explosive',
      refType:'MISSION',
      refId:'treason',
      status:'DEFAULT'
    },
    {
      id: 4,
      order:4,
      type:'LINK',
      // name:'Un jeu glaçant',
      refType:'MISSION',
      refId:'coldGame',
      status:'DEFAULT'
    },
    {
      id: 6,
      order:6,
      type:'LINK',
      // name:'Unknown',
      refType:'MISSION',
      refId:'unknown',
      status:'DEACTIVATED'
    },
  ],
  coldGame: [
    {
      id: 105,
      order:0,
      type:'DIVIDER',
      // name:'Apps',
    },
    {
      id: 100,
      order:1,
      type:'LINK',
      // name:'Tableau de bord',
      refType:'FOLDER',
      refId:null,
      status:'DEFAULT'
    },
    {
      id: 103,
      order:2,
      type:'LINK',
      // name:'Fichiers M27',
      refType:'FOLDER',
      refId:1,
      status:'DEFAULT'
    },
    {
      id: 106,
      order:3,
      type:'LINK',
      // name:'SatCell',
      refType:'APP',
      refId:'SATCELL',
      status:'DEFAULT'
    },
    {
      id: 107,
      order:4,
      type:'LINK',
      // name:'CyberCounter',
      refType:'APP',
      refId:'CYBERCOUNTER',
      status:'PROTECTED'
    },
    {
      id: 104,
      order:10,
      // name:'Objectifs',
      type:'DIVIDER',
    },
    {
      id: 101,
      order:11,
      type:'LINK',
      // name:'Secourir Malory',
      refType:'ENIGMA',
      refId:0,
      status:'UNSOLVED'
    },
    {
      id: 102,
      order:12,
      type:'LINK',
      // name:'Contrer le JMX',
      refType:'ENIGMA',
      refId:1,
      status:'INVISIBLE'
    },
    {
      id: 110,
      order:13,
      type:'LINK',
      // name:`Trouver le contact`,
      refType:'ENIGMA',
      refId:4,
      status:'INVISIBLE'
    },
    {
      id: 111,
      order:14,
      type:'LINK',
      // name:`Retrouver le vol en danger`,
      refType:'ENIGMA',
      refId:5,
      status:'INVISIBLE'
    },
    {
      id: 112,
      order:15,
      type:'LINK',
      // name:`Retrouver M`,
      refType:'ENIGMA',
      refId:6,
      status:'INVISIBLE'
    },
    {
      id: 113,
      order:16,
      type:'LINK',
      // name:`Qui est M?`,
      refType:'ENIGMA',
      refId:7,
      status:'INVISIBLE'
    },
    {
      id: 114,
      order:17,
      type:'LINK',
      // name:`Quitter`,
      refType:'CORE',
      refId:'GO_HOME',
      status:'INVISIBLE'
    },
    {
      id: 115,
      order:14,
      type:'LINK',
      // name:`Retrouver la compagnie aérienne`,
      refType:'ENIGMA',
      refId:23,
      status:'INVISIBLE'
    },
  ],
  treason: [
    {
      id: 200,
      order:0,
      type:'DIVIDER',
      // name:'Apps',
    },
    {
      id: 201,
      order:1,
      type:'LINK',
      // name:'Tableau de bord',  
      refType:'FOLDER',
      refId:null,
      status:'DEFAULT'
    },
    {
      id: 210,
      order:3,
      type:'LINK',
      // name:'Indices',  
      refType:'CORE',
      refId:'HINT',
      status:'DEFAULT'
    },
    {
      id: 205,
      order:5,
      type:'DIVIDER',
      // name:'Objectifs',
    },
    {
      id: 202,
      order:6,
      type:'LINK',
      // name:`Infiltrer l'ordinateur`,
      refType:'ENIGMA',
      refId:15,
      status:'DEFAULT'
    },
    {
      id: 203,
      order:6,
      type:'LINK',
      // name:`Pirater MissilGouv`,
      refType:'ENIGMA',
      refId:16,
      status:'DEACTIVATED'
    },
    {
      id: 204,
      order:6,
      type:'LINK',
      // name:`Empêcher le décollage`,
      refType:'ENIGMA',
      refId:17,
      status:'DEACTIVATED'
    },
    {
      id: 206,
      order:6,
      type:'LINK',
      // name:`Neutraliser le missile`,
      refType:'ENIGMA',
      refId:17,
      status:'INVISIBLE'
    },
  ]
}