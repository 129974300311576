/*
 * endingPanel Data: name, id
 */
import {openAdditionalPanel, setCurrentScene} from 'discapecommon/actions/index'
import {log} from '../utils'
import endingPanels from '../consts/endingPanels'

/*
  Presentation de la endingPanel
*/

const defaultState = {}

const endingPanel = (state = defaultState, action) => {
  switch (action.type) {
    case 'OPEN_ENDING_PANEL':
      const endingPanel = endingPanels.find(({id}) => id == action.id)
      if(!endingPanel)
        throw 'Unable to find endingPanel';
      
      action.asyncDispatch(setCurrentScene('END_SCENE'));
      return {...state, ...endingPanel};
    default:
      return state
  }
}

export default endingPanel
