import jmx from '../../assets/images/jmx.jpg'
import femme1 from '../../assets/missions/coldGame/images/Femme1Small.jpg'
import femme2 from '../../assets/missions/coldGame/images/femme2.jpg'
import jose from '../../assets/missions/coldGame/images/JosePaquin.jpg'
import man1 from '../../assets/missions/coldGame/images/Man1.jpg'
import {fNumber} from '../utils'

export default [
  {
    id:1, // ColdGame
    name: 'endingPanel.template.missionSuccess',
    content: [
      {type:'h3', localeKey: 'endingPanel.template.reportTitle'},
      {type:'p',  localeKey:'endingPanel.1.reportContent'},
      {type:'h3', localeKey: `endingPanel.template.interventionDuration`},
      {type:'p',  value:`{gameDuration}`,},
    ],
    actions: [
      {
        type: 'button',
        name: 'GO_HOME',
        localeKey: 'endingPanel.template.goHome',
      },
      {
        type: 'button',
        name: 'VIEW_MISSION',
        value: 'chase-{gameMode}',
        localeKey: 'endingPanel.template.viewNext'
      },
    ]
  },
  {
    id:2, // ColdGame Echec
    name: 'endingPanel.template.missionFailed',
    content: [
      {type:'h3', localeKey: 'endingPanel.template.reportTitle'},
      {type:'p', localeKey: `endingPanel.2.reportContent`},
      {type:'h3', localeKey: `endingPanel.template.interventionDuration`},
      {type:'p', value:`{gameDuration}`,},
    ],
    actions: [
      {
        type: 'button',
        name: 'GO_HOME',
        localeKey: 'endingPanel.template.goHome'
      },
      {
        type: 'button',
        name: 'VIEW_MISSION',
        value: 'coldGame-{gameMode}',
        localeKey: 'endingPanel.template.tryAgain'
      },
    ]
  },
  {
    id:3, // ColdGame Echec Malory died
    name: 'endingPanel.template.missionFailed',
    content: [
      {type:'h3', localeKey: 'endingPanel.template.reportTitle'},
      {type:'p', localeKey: `endingPanel.3.reportContent`},
      {type:'h3', localeKey: `endingPanel.template.interventionDuration`},
      {type:'p', value:`{gameDuration}`,},
    ],
    actions: [
      {
        type: 'button',
        name: 'GO_HOME',
        localeKey: 'endingPanel.template.goHome'
      },
      {
        type: 'button',
        name: 'VIEW_MISSION',
        value: 'coldGame-{gameMode}',
        localeKey: 'endingPanel.template.tryAgain'
      },
    ]
  },
  {
    id:4, // ColdGame Echec Voiture explosée
    name: 'endingPanel.template.missionFailed',
    content: [
      {type:'h3', localeKey: 'endingPanel.template.reportTitle'},
      {type:'p', localeKey: `endingPanel.4.reportContent`},
      {type:'h3', localeKey: `endingPanel.template.interventionDuration`},
      {type:'p', value:`{gameDuration}`,},
    ],
    actions: [
      {
        type: 'button',
        name: 'GO_HOME',
        localeKey: 'endingPanel.template.goHome'
      },
      {
        type: 'button',
        name: 'VIEW_MISSION',
        value: 'coldGame-{gameMode}',
        localeKey: 'endingPanel.template.tryAgain'
      },
    ]
  },
  {
    id:5, // Treason Echec
    name: 'endingPanel.template.missionFailed',
    content: [
      {type:'h3', localeKey: 'endingPanel.template.reportTitle'},
      {type:'p', localeKey: `endingPanel.5.reportContent`},
      {type:'h3', localeKey: `endingPanel.template.interventionDuration`},
      {type:'p', value:`{gameDuration}`,},
    ],
    actions: [
      {
        type: 'button',
        name: 'GO_HOME',
        localeKey: 'endingPanel.template.goHome'
      },
      {
        type: 'button',
        name: 'VIEW_MISSION',
        value: 'treason-{gameMode}',
        localeKey: 'endingPanel.template.tryAgain'
      },
    ]
  },
  {
    id:6, // Treason Success
    name: 'endingPanel.template.missionSuccess',
    content: [
      {type:'h3', localeKey: 'endingPanel.template.reportTitle'},
      {type:'p', localeKey: `endingPanel.6.reportContent`},
      {type:'h3', localeKey: `endingPanel.template.interventionDuration`},
      {type:'p', value:`{gameDuration}`},
    ],
    actions: [
      {
        type: 'button',
        name: 'GO_HOME',
        localeKey: 'endingPanel.template.goHome'
      },
      {
        type: 'button',
        name: 'VIEW_MISSION',
        value: 'coldGame-{gameMode}',
        localeKey: 'endingPanel.template.viewNext'
      },
    ]
  },
  {
    id:7, // ColdGame Echec Avion explosé
    name: 'endingPanel.template.missionFailed',
    content: [
      {type:'h3', localeKey: 'endingPanel.template.reportTitle'},
      {type:'p', localeKey: `endingPanel.7.reportContent`},
      {type:'h3', localeKey: `endingPanel.template.interventionDuration`},
      {type:'p', value:`{gameDuration}`,},
    ],
    actions: [
      {
        type: 'button',
        name: 'GO_HOME',
        localeKey: 'endingPanel.template.goHome'
      },
      {
        type: 'button',
        name: 'VIEW_MISSION',
        value: 'coldGame-{gameMode}',
        localeKey: 'endingPanel.template.tryAgain'
      },
    ]
  },
]