/*
 * dataSheet Data: name, id
 */
import {openAdditionalPanel, setContentPanel} from 'discapecommon/actions/index'
import {log} from '../utils'
import dataSheets from '../consts/dataSheets'

/*
Presentation de la dataSheet
*/

const defaultState = {}

const dataSheet = (state = defaultState, action) => {
  switch (action.type) {
    case 'OPEN_DATASHEET':
      const dataSheet = dataSheets.find(({key}) => key == action.key)
      if(!dataSheet)
        throw 'Unable to find dataSheet';
      
      action.asyncDispatch(setContentPanel('DATASHEET'));
      return {...state, dataSheetKey: action.key, ...dataSheet};
    default:
      return state
  }
}

export default dataSheet
