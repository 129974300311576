/*
* Main script for the remote control pad
*/
 
import eventManager from './eventManager'
import {log, logError} from './utils.js';
import io from 'socket.io-client';
import { getStoredToken } from './services/userService'

class NetworkManager {
  constructor() {
    console.log('token ', getStoredToken())
    this.socket = io({
      query: {
        token: getStoredToken(), 
      },
      autoConnect: false
    });

    this.socket.on("error", (error) => {
      console.error('socket error: ', error)
    });
    
    this.socket.io.on("error", (error) => {
      console.error('socket.io error: ', error)
    });
    this.socket.on("connect_error", (error) => {
      console.error('socket connect_error: ', error)
    });

    this.socket.connect();

    this.handleEvents();
  }

  getSocket() {
    return this.socket;
  }

  updateQuery() {
    this.socket.io.opts.query = {
      token: getStoredToken(), 
    }
  }

  handleEvents() {
    eventManager.on('concreteAction', data => {
        this.socket.emit('concreteAction', data);
        console.log('Emitting concreteAction: ', data)
      }
    );

    this.socket.on('concreteAction', data => {
      log('Action: '+JSON.stringify(data));
    })

    // Duplicated in the store
    this.socket.on('action', data => {
      // eventManager.emit('action', data);
    })

    this.socket.on('occuringEvent', data => {
      switch(data.type) {
        case 'COUNT_DOWN_UPDATE':
        case 'ELAPSED_SECONDS_UPDATE':
        case 'PAD_BUTTON_DOWN':
        case 'STATUS_UPDATE':
        case 'LANGUAGE_UPDATE':
        case 'GPIO_READ_UPDATE':
        case 'PAD_INPUT_CHECKBOX_UNCHECKED':
        case 'PAD_INPUT_CHECKBOX_CHECKED':
        case 'GPIO_READ_UPDATE':
        case 'VARIABLE_UPDATE':
        case 'SFX_ENDED':
          break;
        default:
          log('Event: '+JSON.stringify(data));
      }
      eventManager.emit('occuringEvent', data);
    }) 

    this.socket.on('notification', data => {
      eventManager.emit('notification', data)
      if(data.isImportant)
        log('Event: '+JSON.stringify(data));
    })

    eventManager.on('updateUserData', () => {
      this.updateQuery();
    })
  }
}

export const networkManager = new NetworkManager();

export const socket = networkManager.getSocket();