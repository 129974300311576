export const ENIGMAS = [
// TREASON
  {
    id:3,
    name: 'enigma.3.name',
    answer: 'YES, oui, ui, yeah',
    isSolved:false,
    inputType: 'none',
    autoCloseValid: true,
    autoCloseInvalid: true,
    forbidClickOut: true,
    actionButtons: [
      {
        name: 'response',
        value: 'NO',
        content: 'enigma.template1.no'
      },
      {
        name: 'response',
        value: 'YES',
        content: 'enigma.template1.yes'
      }
    ]
  },
  {
    id: 0,
    name: 'enigma.0.name',
    question: 'enigma.0.question',
    // question: '[EN:What is current year?][FR:En quelle année sommes nous ?]',
    validMessage: `enigma.0.valid`,
    invalidMessage: `enigma.0.invalid`,
    fields: [
      {
        fieldId:0,
        label: 'enigma.0.field0Name',
        answer: 'lambeth',
        inputType: 'text',
      },
      {
        fieldId:1,
        label: 'enigma.0.field1Name',
        answer: 'newport, newportstreet, newport street, newport st, newport st.',
        inputType: 'text',
      },
    ],
    language: 'fr',
    activityId: 1,
    autoCloseValid: true,
    autoCloseInvalid: false,
    isSolved:false,
  },
  {
    id: 1,
    name: 'enigma.1.name',
    question: 'enigma.1.question',
    inputType: 'none',
    language: 'fr',
    activityId: 1,
    actionButtons: [
      {
        name: 'response',
        value: 'close',
        content: 'enigma.template1.ok'
      },
    ]
  },
  {
    id: 4,
    name: 'enigma.4.name',
    question: `enigma.4.question`,
    answer: 'jose, josé, josée, josee, jose pacquin, jose paquin, josé paquin',
    invalidMessage: `enigma.4.invalid`,
    inputType: 'text',
    language: 'fr',
    activityId: 1,
    autoCloseValid: true,
    autoCloseInvalid: false,
    isSolved:false,
  },
  {
    id: 5,
    name: 'enigma.5.name',
    question: `enigma.5.question`,
    answer: 'GK 452, GK452',
    inputType: 'text',
    activityId: 1,
    autoCloseValid: true,
    autoCloseInvalid: false,
    isSolved:false,
  },
  { // Ou est M
    id: 6,
    name: 'enigma.6.name',
    question: `enigma.6.question`,
    fields: [
      {
        fieldId:0,
        label: 'enigma.6.field0Name',
        answer: 'london, londres, londre',
        inputType: 'text',
      },
      {
        fieldId:1,
        label: 'enigma.6.field1Name',
        answer: 'city of london, city, cité, cordwainer, ward of cheap, ville de londres, cité de londres, cite de londres, Cheapside, Cheapsides',
        inputType: 'text',
      },
    ],
    activityId: 1,
    autoCloseValid: true,
    autoCloseInvalid: false,
    isSolved:false,
  },
  {
    id: 7,
    name: 'enigma.7.name',
    question: `enigma.7.question`,
    answer: 'MALORY',
    inputType: 'none',
    language: 'fr',
    activityId: 1,
    autoCloseValid: true,
    autoCloseInvalid: true,
    isSolved:false,
    actionButtons: [
      {
        name: 'response',
        value: 'MALORY',
        content: 'enigma.7.malory'
      },
      {
        name: 'response',
        value: 'PENNYWORTH',
        content: 'enigma.7.pennyworth'
      },
      {
        name: 'response',
        value: 'MARTIN',
        content: 'enigma.7.martin'
      },
    ]
  },
  {
    id: 8,
    name: 'enigma.8.name',
    question: 'enigma.8.question',
    answer: '63158, 85136',
    inputType: 'lockScreen',
    autoCloseValid: true,
    autoCloseInvalid: false,
    isSolved:false,
    activityId:0,
    actionButtons: [],
    unlockType:'FILETREE',
    unlockId: 1001,
  },
  {
    id: 9,
    name: `enigma.9.name`,
    question: `enigma.9.question`,
    answer: 'OK',
    inputType: 'none',
    autoCloseValid: true,
    autoCloseInvalid: true,
    isSolved:false,
    activityId:0,
    type:'ALERT',
    options: {
      showCountDown:true,
      countDownId: 0,
    },
    actionButtons: [],
  },
  {
    id: 10,
    name: 'enigma.10.name', // Missile lancé
    question: `enigma.10.question`,
    answer: 'OK',
    inputType: 'none',
    autoCloseValid: true,
    autoCloseInvalid: true,
    isSolved:false,
    activityId:0,
    actionButtons: [
      {
        name: 'response',
        value: 'OK',
        content: 'enigma.template1.ok'
      },
    ],
  },
  {
    id: 11,
    name: 'enigma.11.name',
    question: `enigma.11.question`,
    answer: 'OK',
    inputType: 'none',
    autoCloseValid: true,
    autoCloseInvalid: true,
    isSolved:false,
    activityId:0,
    actionButtons: [
      {
        name: 'response',
        value: 'MAIN_MENU',
        content: 'enigma.template1.mainMenu'
      },
      {
        name: 'response',
        value: 'OK',
        content: 'enigma.template1.restartMission'
      },
    ],
  },
  {
    id: 12,
    name: 'enigma.12.name',
    question: `enigma.12.question`,
    answer: 'OK',
    inputType: 'none',
    autoCloseValid: true,
    autoCloseInvalid: true,
    isSolved:false,
    activityId:0,
    actionButtons: [
      {
        name: 'response',
        value: 'OK',
        content: 'enigma.template1.ok'
      },
    ],
  },
  {
    id: 13,
    name: 'enigma.13.name',
    question: 'enigma.13.question',
    // question: '[EN:What is current year?][FR:En quelle année sommes nous ?]',
    validMessage: `OK`,
    language: 'fr',
    activityId: 1,
    autoCloseValid: true,
    autoCloseInvalid: true,
    isSolved:false,
    inputType:'none',
    actionButtons: [
      {
        name: 'response',
        value: 'OK',
        content: 'enigma.template1.ok'
      },
    ],
  },
  { // Treason Mission success 
    id: 14,
    name: 'enigma.14.name',
    question: 'enigma.14.question',
    answer: `OK`,
    language: 'fr',
    activityId: 1,
    autoCloseValid: true,
    autoCloseInvalid: true,
    isSolved:false,
    inputType:'none',
    actionButtons: [
      {
        name: 'response',
        value: 'Menu',
        content: 'enigma.template1.mainMenu'
      },
      {
        name: 'response',
        value: 'OK',
        content: 'enigma.template1.viewNext'
      },
    ],
  },
  {
    id: 15,
    name: `enigma.15.name`,
    question: 'enigma.15.question',
    answer: `OK`,
    inputType:'none',
    activityId: 1,
    autoCloseValid: true,
    autoCloseInvalid: true,
    actionButtons: [
      {
        name: 'response',
        value: 'close',
        content: 'enigma.template1.ok'
      },
    ]
  },  
  {
    id: 16,
    name: `enigma.16.name`,
    question: `enigma.16.question`,
    answer: `OK`,
    activityId: 1,
    inputType:'none',
    autoCloseValid: true,
    autoCloseInvalid: true,
    actionButtons: [
      {
        name: 'response',
        value: 'close',
        content: 'enigma.template1.ok'
      },
    ]
  },
  {
    id: 17,
    name: `enigma.17.name`,
    question: 'enigma.17.question',
    answer: `OK`,
    activityId: 0,
    inputType:'none',
    autoCloseValid: true,
    autoCloseInvalid: true,
    actionButtons: [
      {
        name: 'response',
        value: 'close',
        content: 'enigma.template1.ok'
      },
    ]
  },
  {
    id: 22, // Error: Session introuvable
    name: 'enigma.22.name',
    question: `enigma.22.question`,
    answer: 'OK',
    inputType: 'none',
    autoCloseValid: true,
    autoCloseInvalid: true,
    isSolved:false,
    activityId:0,
    actionButtons: [
      {
        name: 'response',
        value: 'OK',
        content: 'enigma.template1.ok'
      },
    ],
  },  
  {
    id: 23, // Find air company
    name: 'enigma.23.name',
    question: `enigma.23.question`,
    answer: 'corsair, corsaire',
    invalidMessage: `enigma.23.invalid`,
    inputType: 'text',
    autoCloseValid: true,
    autoCloseInvalid: true,
    isSolved:false,
    activityId:1,
  },
  {
    id: 24, // Removed from group
    name: 'enigma.24.name',
    question: `enigma.24.question`,
    answer: 'OK',
    inputType: 'none',
    autoCloseValid: true,
    autoCloseInvalid: true,
    isSolved:false,
    activityId:0,
    actionButtons: [
      {
        name: 'response',
        value: 'OK',
        content: 'enigma.template1.ok'
      },
    ],
  },
  {
    id: 115, // E1: Fichiers audio
    name: 'enigma.115.name',
    question: `enigma.115.question`,
    answer: 'NotOK',
    inputType: 'none',
    isSolved:false,
    autoCloseInvalid: true,
    activityId:0,
    actionButtons: [
      {
        name: 'response',
        value: 'OK',
        content: 'enigma.template1.ok'
      },
    ],
  },
]