import getTextDate from '../helpers/getTextDate'

export const fr = {
  'home.section1.slogan': 'Escape game en ligne',
  'home.section1.button': 'Voir les missions',
  'homepage.catchphrase': `Le défi d'une escape room, {br}100% en ligne !`,
  'home.section1.trial': 'ESSAYER LA MISSION DE DECOUVERTE (10min)',
  'home.section1.playNow': 'JOUER MAINTENANT',
  'home.section1.organiseEvent': 'ORGANISER UN EVENEMENT',
  'home.section2.description': `Discape est une escape game à distance de 60 minutes ! Vous devrez mener l'enquête à propos d'un hacker Londonien ! Faites équipes pour trouver des indices et résoudre des puzzles!`,
  'homepage.descriptionSection.title': 'Dans quelques minutes, plongez dans une mission captivante !',
  'homepage.descriptionSection.longDescription': `Prêt pour une aventure façon escape room ? Discape propose un jeu d'aventure à domicile rempli de codes à déchiffrer, 
    de pistes à découvrir et d'énigmes à résoudre. Si vous aimez les escape rooms, vous adorerez naviguer à travers ces 
    mystères en ligne. La réussite exigera du raisonnement déductif, de la réflexion critique et un brin d'astuce ! 
    Les joueurs peuvent se lancer dans leur mission à leur convenance, en mettant en pause, en sauvegardant leur progression 
    et en reprenant quand ils le souhaitent.`,
  'faq.howDoesItWork': 'Comment ça marche?',
  'faq.howDoesItWorkText': `En équipe avec vos amis, vous devrez utiliser plusieurs appareils (téléphone ou ordinateur) connectés ensemble pour accomplir votre mission.
    Une fois la mission lancée, vous pourrez discuter ensemble sur le chat ou vous pourrez utiliser une application tierce pour discuter avec la voix.
    Pour toute demande d'assistance, contactez-nous à <a>info@discapegame.com</a>.`,
  'faq.willIBeGroupedWithStrangers': 'Est-ce que je serai groupé avec des inconnus?',
  'faq.willIBeGroupedWithStrangersText': `C'est vous qui contrôlez les participants qui sont dans votre session. Gardez la session comme étant "privé" pour éviter qu'un inconnu ne rejoigne.`,
  'faq.ageLimit': `Quel est l'age limite?`,
  'faq.ageLimitText': `Nous recommandons nos jeux pour les plus de 12 ans. Nous autorisons les participants plus jeunes, mais certaines parties du contenu peuvent être trop difficiles pour eux.`,
  'faq.largeGroup': `Pouvez-vous accueillir de grands événements ou des fêtes d'entreprise?`,
  'faq.largeGroupText': `Absolument! Nous adorons héberger de grand événements et fêtes d'entreprise.
    Nous avons une équipe dédiée pour vous aider à coordonner tous les détails pour vous.
    Vous pouvez contacter notre équipe par email au <a>info@discapegame.com</a> pour plus d'information.`,
  'faq.howLongDoesAGameLast': `Combien de temps dure un jeu?`,
  'faq.howLongDoesAGameLastText': `Nos jeux ont des durées variables, allant de 20 à 60 minutes.`,
  'faq.gameLanguage': `Le jeu est-il en français et en anglais?`,
  'faq.gameLanguageText': `Les jeux sont jouables en français et en anglais. Vous pouvez changer de langue grâce à l'icône de la planète dans la barre du menu`,
  'faq.IHaveBeenDisconnected': `J'ai été déconnecté! Est-ce que je peux me reconnecter?`,
  'faq.IHaveBeenDisconnectedText': `Tout à fait. Nous avons conçu cette plateforme pour gérer tout type de déconnexion / reconnexion.
    Il faudra alors recharger la page avec la même adresse URL.`,
  'faq.isThereHelpAvailable': `Est-ce qu'on peut avoir de l'aide dans la mission?`,
  'faq.isThereHelpAvailableText': `Oui, vous pouvez accéder aux indices de la mission en cliquant sur le bouton d'aide.`,
  'faq.isMyDeviceCompatible': `Quel type d'appareil fonctionne?`,
  'faq.isMyDeviceCompatibleText': `L'application fonctionne sur ordinateur et tablette et téléphone intelligent plus récent que 2015, .
    Si vous avez des problèmes techniques, contactez-nous à <a>info@discapegame.com</a>.`,
  'template.button.state.save': 'Sauvegarder',
  'template.button.state.error': 'Erreur',
  'template.button.state.upToDate': 'À jour',
  'template.button.state.saved': 'Sauvegardé',
  'template.button.state.waiting': 'En attente',
  'template.button.submit': `Envoyer`,
  'template.button.home': `Accueil`,
  'template.button.copyLink': `Copier le lien`,
  'template.message.copiedLink': `Lien copié`,
  'dashboard.name': 'Accueil',
  'app.loading': 'Chargement',
  'dashboard.navBarTitle': 'Sélectionnez votre mission',
  'conversation.title': 'Conversation',
  'conversation.you': 'Vous',
  'conversation.instruction': 'Saisissez un message...',
  'conversation.joinedRoom': `{username} a rejoint la session`,
  'conversation.disconnected': `{username} a été déconnecté`,
  'navBar.profile': 'Profile',
  'navBar.logIn': 'Se connecter',
  'navBar.signUp': `S'inscrire`,
  'navBar.logOut': `Se déconnecter`,
  'navBar.settings': `Paramètres`,
  'navBar.leaveSession': `Quitter la session`,
  'auth.title.signUp': `S'inscrire`,
  'auth.title.logIn': `Se Connecter`,
  'auth.label.email': `Email`,
  'auth.label.oldPassword': `Ancien mot de passe`,
  'auth.label.newPassword': `Nouveau mot de passe`,
  'auth.label.password': `Mot de passe`,
  'auth.label.confirmation': `Confirmation`,
  'auth.label.verificationCode': `Code de vérification`,
  'auth.label.username': `Nom d'agent`,
  'auth.label.submit': `Envoyer`,
  'auth.label.home': `Accueil`,
  'auth.signUp.success': `Vous êtes maintenant enregistré et connecté.`,
  'auth.logIn.success': `Vous êtes maintenant connecté.`,
  'auth.logIn.passwordLost': `Mot de passe perdu?`,
  'auth.retrieval.newPassword': `Nouveau mot de passe`,
  'auth.retrieval.title': `Retrouver mon mot de passe`,
  'auth.retrieval.codeValidated': `Votre code a bien été validé!`,
  'auth.retrieval.enterNewPassword': `Veuillez entrer votre nouveau mot de passe:`,
  'auth.retrieval.creationSuccess': `Votre nouveau mot de passe a bien été enregistré!`,
  'profile.infos.tabTitle': `infos`,
  'profile.infos.title': `Infos`,
  'profile.password.tabTitle': `Mot de passe`,
  'profile.password.title': `Mot de passe`,
  'settings.title': `Paramètres`,
  'settings.language.name': 'Langue',
  'settings.musicVolume.name': `Volume de la musique`,
  'renameDialog.title': `Entrez votre nom d'utilisateur`,
  'footer.about': 'A Propos',
  'footer.help': 'Aide',
  'footer.press': 'Presse',
  'footer.privacy': 'Vie Privée',
  'footer.copyright': '© 2024 DISCAPE',
  'missionList.breadcrumbsName': 'Liste des missions',
  'missionList.header': 'Choisissez votre mission',
  'missionList.headerSolo': 'EN SOLO',
  'missionList.headerMulti': 'EN ÉQUIPE',
  'missionList.headerMultiExplanation': `Rejoignez vos coéquipiers en ligne pour accomplir ensemble ces missions! {br}Cliquez pour en savoir plus`,
  'missionCatalog.details.playNow': 'Jouer',
  'missionCatalog.details.buyNow': 'Acheter',
  'missionCatalog.price': 'Prix',
  'missionCatalog.free': 'Gratuit',
  'missionCatalog.viewTrailer': 'Voir le brief',
  'missionCatalog.details.wip': 'Encore en développement',
  'missionCatalog.list.start': 'Voir plus',
  'missionDetails.startMission': 'Démarrer la mission',
  'missionDetails.joinOrCreateSession': 'Rejoindre ou créer une session',
  'sessionSelector.selectSession': 'Sélectionnez une session',
  'sessionSelector.joinSessionHeading': 'Rejoindre une session',
  'sessionSelector.createNewSessionSolo': 'Lancer en solo',
  'sessionSelector.createNewSession': 'Créer une nouvelle session',
  'sessionSelector.noSessionAvailable': 'Pas de session disponible.',
  'sessionSelector.createYours': 'Créez la vôtre!',
  'sessionSelector.explanation': `La session de votre coéquipier apparaîtra ici. Vous devrez compléter le code de sa session. Vous pouvez également créer une session et lui partager le code qui vous sera indiqué.`,
  'sessionSelector.enterLastDigits': 'Entrez les 3 derniers caractères de la session',
  'sessionSelector.verify': 'Vérifier',
  'lobby.sharableLink': `Lien à partager`,
  'lobby.recommandedParticipantCount': `Participants recommandés:`,
  'lobby.duration': `Durée:`,
  'lobby.back': `Retour`,
  'lobby.you': `(Vous)`,
  'lobby.userList.title': `Participants:`,
  'lobby.startButton': 'Démarrer',
  'userList.rename': 'Changer de nom',
  'userList.removeUserFromGroup': 'Retirer du groupe',
  'activity.common.timeLeft': 'Temps Restant:',
  'activity.treason.name': 'Une trahison explosive',
  'activity.treason.description': `Mission urgente: le sergent Vilamo a trahi nos rangs! Des 
            indices nous laisse penser qu'il a pris le contrôle d'un 
            logiciel militaire. Neutralisez-le!`,
  'activity.treason.endCondition1': ' avant décollage du missile.',
  'activity.treason.endCondition2': ` avant que le missile n'explose sur le bateau.`,
  'activity.coldGame.name': 'Menés en bâteau',
  'activity.coldGame.description': `Une menace masquée vous lance une série de défis, tous aussi meurtriers que dangereux. Vous n'avez pas le choix, il faudra être plus rapide que lui!`,
  'activity.coldGame.endCondition1': ` avant que la bombe JMX n'explose`,
  'activity.coldGame.endCondition2': ` avant que M n'achève Malory`,
  'activity.coldGame.endCondition3': ` avant que l'avion ne s'écrase`,
  'activity.coldGame.endCondition4': ` avant que la police n'arrive sur vous`,
  'activity.unknown.name': 'Bientôt disponible',
  'activity.unknown.description': `Nos informations sur ce dossier sont encore insuffisant, mais les premières données sont inquiétantes...`,
  'activity.chase.name': 'Chasser des fantômes',
  'activity.chase.description': `Vous recommencez votre enquête à zéro: d’où vient ce M, et quel est son objectif ?`,
  'app.satCell.title': `Carte des appels téléphoniques`,
  'app.satCell.selectHour': `Relevés du {date} (chaque point est un téléphone)`,
  'app.cyberCounter.description1': `Connecté à la cyber-attack. La séquence a été récupérée séparemment par les différents agents de votre équipe. Entrez la dans l'ordre. Les "?" sont des informations manquantes que vous pouvez compléter.`,  //Connected to cyber-attack. The sequence has been spread between the agents of your team:
  'app.cyberCounter.yourPersonnalActions': `Vos actions personnelles.`,  //Your personnal actions:
  'app.cyberCounter.counterAlreadyStarted': `Le contre est déjà lancé! Attendez que les autres agents terminent.`,
  'app.cyberCounter.timeOut': `Temps écoulé ! Essayez encore de contrer le hacking !`,
  'app.cyberCounter.wrongSymbol': `Mauvaise entrée, la session a été perdue.`,
  'app.cyberCounter.success': `Bravo ! Le hacking a été interrompu avec succès.`,
  'app.cyberCounter.goDashboard': `Retourner à l'accueil.`, //Return to dashboard
  'app.cyberCounter.needMorePlayers': `Vous devez être au moins deux agents connectés pour pirater le JMX.`, //Return to dashboard
  'app.vocalEffect.source': 'Source',
  'app.vocalEffect.none': 'Aucun',
  'app.vocalEffect.effect1': 'Effet 1',
  'app.vocalEffect.effect2': 'Effet 2',
  'app.vocalEffect.penny': 'Agent Pennyworth',
  'app.vocalEffect.malory': 'Agent Malory',
  'app.vocalEffect.martin': 'Agent Martin',
  'app.binaryConverter.header': 'Convertisseur binaire',
  'app.cyberCounter.targetedIp': 'Adresse IP visée:',
  'app.cyberCounter.noAnswerOnThisIp': 'Pas de réponse à cette adresse IP',
  'app.cyberCounter.connectionSuccessful': 'Vous êtes connecté avec succès au JMX de la voiture.',
  'app.cyberCounter.connect': 'Se connecter',
  'app.cyberDetectorFrequency.frequence': `Fréquence`, //Return to dashboard
  'app.cyberDetector.noSignal': 'Aucun signal sur cette fréquence',
  'app.cyberDetector.requiresAnotherPlayer': 'Un autre agent doit se connecter à cette application pour écouter pendant votre cartographie.',
  'app.cyberDetector.cartographerPosition': 'Position du cartographe:',
  'app.cyberDetector.userListTitle': 'Postes des agents:',
  'app.cyberDetector.mapOperator': `Opérateur de carte. Lui seul peut la déplacer.`,
  'app.cyberDetector.detector': `Détecteur. Il peut identifier dans quelle direction se trouve le JMX par rapport au cercle bleu.`,
  'app.cyberDetector.decoder': `Décodeur. Il peut consulter les informations des marqueurs.`,
  'app.flightList.title': 'Liste des vols en cours',
  'app.flightList.transporter': 'Transporteur',
  'app.flightList.flightNumber': 'Numéro',
  'app.flightList.from': 'Depuis',
  'app.flightList.to': 'Vers',
  'app.gmaps.search': 'Rechercher',
  'app.missilGouv.title': 'MissilGouv',
  'app.missilGouv.objectiveLocked': 'Cible confirmée: Bâteau Pétrolier; France',
  'app.missilGouv.remainingTime': 'Temps avant lancement: ',
  'app.missilGouv.timeBeforeImpact': 'Temps avant impact: ',
  'app.missilGouv.console': "CONSOLE DE LANCEMENT",
  'app.missilGouv.button1': 'Transfert données exploitation',
  'app.missilGouv.button2': 'Baliser les coordonnés extérieures',
  'app.missilGouv.button3': 'Provoquer les actions barrières',
  'app.missilGouv.button4': 'Répéter le code de dislocation',
  'app.missilGouv.button5': 'Équilibrer le niveau de Kérosène',
  'app.missilGouv.button6': 'Stabiliser les détecteurs',
  'app.missilGouv.emergencyInterface': `Interface de télémétrie`,
  'app.telemetry.telemetryOverload': `Surcharge de la télémétrie`,
  'endingPanel.template.missionSuccess': `Mission Réussie`,
  'endingPanel.template.missionFailed': `Mission Échouée`,
  'endingPanel.template.reportTitle': `Rapport`,
  'endingPanel.template.goHome': `Menu Principal`,
  'endingPanel.template.viewNext': `Prochaine mission`,
  'endingPanel.template.tryAgain': `Recommencer`,
  'endingPanel.template.interventionDuration': `Durée de l'intervention`,
  'endingPanel.1.reportContent': `Les agents {playerList} ont fait preuve de discernement, je les recommande pour la prochaine mission.`,
  'endingPanel.2.reportContent': `Les agents {playerList} sont maintenant en garde à vue, je suis en contact avec la police pour les faire sortir de là.`,
  'endingPanel.3.reportContent': `Un message de M nous indique qu'il a achevé Malory. Échec de la mission.`,
  'endingPanel.4.reportContent': `Nous venons de recevoir un signalement, une voiture a été retrouvée, explosée.`,
  'endingPanel.5.reportContent': `Le missile a explosé sur le bâteau. Aucune victime n'est à déplorer. Cependant les dégâts sur l'écosystème sont considérables en plus de causer un impact financier sur la compagnie, ainsi qu’à l’état Français.`,
  'endingPanel.6.reportContent': `Le missile a explosé dans les airs. Aucune victime ni aucun dommage matériel n'est à déplorer. Bravo!`,
  'endingPanel.7.reportContent': `Un avion à destination de Montréal vient d'exploser au large de l'océan atlantique.`,
  'datasheet.template1.descriptionTitle': 'Description',
  'datasheet.template1.behaviorTitle': 'Comportement',
  'datasheet.template1.conclusionTitle': 'Conclusion',
  'datasheet.discapeAbout.name': 'A Propos',
  'datasheet.discapeAbout.messageTitle': 'Rassembler votre équipe pour partir en mission !',
  'datasheet.discapeAbout.messageContent': `Discape vous propose de vivre une aventure palpitante au coeur du SIG, le Service d'Investigation Général ! Guidés par Jo à travers sa webcam, vous devrez agir vite et bien! Cette aventure à la frontière entre jeu d'évasion, cinéma et jeu vidéo vous promet un challenge sans précédent!`,
  'datasheet.discapeFAQ.name': 'FAQ',
  'datasheet.discapeFAQ.q1Title': 'Comment ça marche?',
  'datasheet.discapeFAQ.q1Content': `En équipe avec vos amis, vous devrez utiliser plusieurs appareils (téléphone ou ordinateur) connectés ensemble pour accomplir votre mission.
                Une fois la mission lancée, vous pourrez discuter ensemble sur le chat ou vous pourrez utiliser une application tierce pour discuter avec la voix.
                Pour toute demande d'assistance, contactez-nous à info@discapegame.com`,
  'datasheet.discapeFAQ.q2Title': `Est-ce que je serai groupé avec d'autres participants?`,
  'datasheet.discapeFAQ.q2Content': `Pas si vous ne le souhaitez pas. Il faut partager les 3 derniers chiffres de l'identifiant de la session à une personne pour qu'elle puisse vous rejoindre. C'est vous qui contrôlez les participants qui sont dans votre session.`,
  'datasheet.discapeFAQ.q3Title': `Comment jouer avec quelqu'un?`,
  'datasheet.discapeFAQ.q3Content': `Vous devez créer une session pour que d'autres personnes puissent vous rejoindre. Une fois créée, partagez leur l'adresse URL de la session (par exemple https://discapegame.com/play/gr1i5ah1)`,
  'datasheet.discapeFAQ.q4Title': `Le jeu est il en français - anglais?`,
  'datasheet.discapeFAQ.q4Content': `Le jeu est en français et la version anglaise va arriver sous peu, bien que tout soit déjà jouable en anglais. Pour changer la langue, allez dans "Paramètres"`,
  'datasheet.jmx.name': `JMX`,
  'datasheet.jmx.descriptionContent': `Une fois implanté dans l'ordinateur de la voiture, le JMX attendra que la voiture atteigne 80km/h pour s'activer. Il perturbe alors les contrôles du véhicule et bloque les freins, le plus souvent menant à un accident mortel. Si ce n'est pas suffisant, il envoie une décharge dans le réservoir pour le faire exploser.`,
  'datasheet.jmx.useTitle': `Utilisation`,
  'datasheet.jmx.useContent': `Idéal pour faire disparaître quelqu'un en faisant croire à un accident. Placer le JMX dans l'ordinateur de bord, sur la pin 12.`,
  'datasheet.jmx.detectionTitle': `Détection`,
  'datasheet.jmx.detectionContent': `Le JMX émet un signal sur la bande AM en 615.55 que l'on peut capter à plus de 2000 km. Il n'est utilisable que sur les voitures munies d'un ordinateur de bord de catégorie Z Européenne.`,
  'datasheet.jose.name': `José Paquin`,
  'datasheet.jose.descriptionContent': `Homme de peau blanche, barbe rasée, cheveux bruns frisés semi-courts. Il travaille comme contrôleur aérien à la tour de contrôle de l'aéroport Pierre-Elliott Trudeau à Montréal, Québec, Canada.`,
  'datasheet.jose.behaviorContent': `Il est arrivé souvent en retard autour du 10 avril 2020. Il a été appelé plusieurs fois par Nils au cours du dernier mois.`,
  'datasheet.jose.conclusionContent': `J'ai demandé à la police locale de l'interroger, mais ça n'a rien donné. Je garde un oeil sur lui.`,
  'datasheet.judith.name': `Judith Rivoli`,
  'datasheet.judith.descriptionContent': `Femme de peau blanche, cheveux bruns longs et lisses. Ingénieure des système d'aération à la centrale nucléaire de Gravelines, en France.`,
  'datasheet.judith.behaviorContent': `Elle a reçu 3 appels de Nils. Sa supérieure a indiqué qu'elle a été particulièrement distraite autour du 5 avril 2020.`,
  'datasheet.judith.conclusionContent': `Interrogation par la police locale, elle a parlé d'un homme qui l'appelait souvent, mais ça n'a rien donné plus exactement. Je l'ai mise sur écoute.`,
  'datasheet.nils.name': `Nils Brown`,
  'datasheet.nils.descriptionContent': `Homme anglais de peau blanche, cheveux bruns courts. Il travaille au parlement de Londres, en Angleterre.`,
  'datasheet.nils.behaviorContent': `J'ai identifié des traces venant de son appartement lors de l'attaque de Paris. Il est impliqué mais je ne sais pas dans quoi. Il est allé en weekend à La Rochelle la semaine dernière.`,
  'datasheet.nils.conclusionContent': `Je le suis personnellement. Ses habitudes sont assez classiques: il fait ses courses le mardi, il ne voit pas grand monde. Il est souvent au téléphone.`,
  'datasheet.ariane.name': `Ariane Estapolo`,
  'datasheet.ariane.descriptionContent': `Femme de peau noire, cheveux bruns courts bouclés. Elle travaille à la Capital Bank, Angleterre.`,
  'datasheet.ariane.behaviorContent': `Elle a été contacté 6 fois par ce Nils Brown. Je n'arrive pas à comprendre leur relation.`,
  'datasheet.ariane.conclusionContent': `Suivie par la police locale. Pas d'activité suspecte.`,
  'datasheet.vilamo.name': `Sergent Eric Vilamo`,
  'datasheet.vilamo.descriptionContent': `Homme blanc de 1m 87. Il était sergent dans l'ANSSI française jusqu'en 2019, puis il a disparu sans laisser de trace.`,
  'datasheet.vilamo.behaviorContent': `Spécialisé dans les systèmes liés aux lancements de missiles, il a déjoué plusieurs attaques informatiques qui visaient les installations françaises. <b>On peut encore retrouver le symbole de son grade utilisé comme signature lors de ses succès.</b>`,
  'datasheet.vilamo.conclusionContent': `Les données indiquent qu'il a programmé le lancement d'un missile Russe sur un bateau pétrolier Français. Il faut l'arrêter à tout prix.`,
  'datasheet.joseMessage.name': `Message Crypté de Jose Paquin`,
  'datasheet.joseMessage.messageTitle': `Message reçu le 2 mai 2019`,
  'datasheet.joseMessage.messageContent': `Destination: 011110010111010101101100`,
  'enigma.template1.no': 'Non',
  'enigma.template1.yes': 'Oui',
  'enigma.template1.ok': 'Bien reçu',
  'enigma.template1.mainMenu': `Menu Principal`,
  'enigma.template1.restartMission': `Recommencer la mission`,
  'enigma.template1.viewNext': `Voir la mission suivante`,
  'enigma.0.name': `Secourir Malory`,
  'enigma.0.question': `Où se trouve-t-elle?`,
  'enigma.0.field0Name': `Quartier`,
  'enigma.0.field1Name': `Rue`,
  'enigma.0.valid': `C'est noté, j'envoie un agent tout de suite!`,
  'enigma.0.invalid': `Nos agents sont allés voir et n'ont rien trouvé. Êtes-vous certain?`,
  'enigma.1.name': `Contrer le JMX`,
  'enigma.1.question': `Trouvez comment se connecter au JMX et le contrer!`,
  'enigma.3.name': `Est-ce que je peux compter sur vous ?`,
  'enigma.4.name': `Trouver le contact de M`,
  'enigma.4.question': `M a dû contacter quelqu'un de l'aviation! L'avez-vous trouvé?`,
  'enigma.4.invalid': `Je ne trouve rien sur cette personne. Êtes-vous certain de son nom?`,
  'enigma.5.name': `Identifier le vol en danger`,
  'enigma.5.question': `Quel est l'avion visé par M?`,
  'enigma.6.name': `Retrouver M`,
  'enigma.6.question': `Il a dû faire une erreur! Dans quelle ville et dans quel quartier se trouve M?`,
  'enigma.6.field0Name': `Ville`,
  'enigma.6.field1Name': `Quartier`,
  'enigma.7.name': `Qui est M?`,
  'enigma.7.question': `Attention, c'est une question décisive. Cela peut mettre en danger nos agents!`,
  'enigma.7.malory': 'Malory',
  'enigma.7.pennyworth': 'Pennyworth',
  'enigma.7.martin': 'Martin',
  'enigma.8.name': `Accès sécurisé`,
  'enigma.8.question': `Sergent Vilamo`,
  'enigma.9.name': `Alerte: Infiltration détectée`,
  'enigma.9.question': `Séquence piège reconnue
      Accélération du compte à rebours`,
  'enigma.10.name': `Missile Lancé`,
  'enigma.10.question': `Impact dans 8 minutes`,
  'enigma.11.name': `MISSION ÉCHOUÉE`,
  'enigma.11.question': `Le missile a explosé sur le bâteau. Aucune victime n'est à déplorer, mais les autorités accusent la Russie de l'attaque. La tension monte...`,
  'enigma.12.name': `Missile détruit`,
  'enigma.12.question': `Le missile a explosé dans les airs. Aucune victime n'est à déplorer, ni aucun dommage matériel. Bravo!`,
  'enigma.13.name': `Secourir Malory`,
  'enigma.13.question': `Malory vient de se faire jeter par la fenêtre! Elle doit être dans la rue, entourée de civils qui appellent les secours. 
    Objectif: trouver dans quelle rue se trouve Malory.
    Utilisez le bouton "Secourir Malory" du menu de gauche quand vous l'aurez trouvée`,
  'enigma.14.name': `MISSION RÉUSSIE`,
  'enigma.14.question': `Vous avez gagné votre place au sein du SIG!`,
  'enigma.15.name': `Infiltrer le dossier crypté de Vilamo`,
  'enigma.15.question': `Il semble avoir utilisé une signature particulière.`,
  'enigma.16.name': `Pirater le logiciel MissilGouv`,
  'enigma.16.question': `Vous devrez travailler en équipe pour reconstituer les deux fragments audio qu'il a laissé!`,
  'enigma.17.name': `Neutraliser le missile`,
  'enigma.17.question': `La séquence de Vilamo était un piège! Il va falloir saturer la télémétrie du missile pour le faire exploser!`,
  'enigma.22.name': `SESSION INTROUVABLE`,
  'enigma.22.question': `La session sélectionnée est introuvable`,
  'enigma.23.name': `Compagnie aérienne`,
  'enigma.23.question': `José devait savoir quelque chose. Quelle est la compagnie aérienne de l'avion visé par M ?`,
  'enigma.24.name': `Retiré du groupe`,
  'enigma.24.question': `Vous avez été retiré du groupe par l'animateur`,
  'enigma.115.name': 'Fichiers audio verrouillé',
  'enigma.115.question': `Vous n'avez pas accès à ce fichier, mais votre coéquipiez aura peut-être accès.`,
  'fileTree.1000': 'Rappeler Joe',
  'fileTree.1001': `Dossier crypté de Vilamo`,
  'fileTree.1002': `MissilGouv 2.3`,
  'fileTree.1003': `Sergent Vilamo`,
  'fileTree.1004': `Carte transport - Sergent Vilamo`,
  'fileTree.1005': `Note Vilamo Part 1/2`,
  'fileTree.1006': `Note Vilamo Part 2/2`,
  'fileTree.1': `Fichiers M27`,
  'fileTree.10': `Notes de Malory`,
  'fileTree.11': `Notes ${getTextDate(-6)}`,
  'fileTree.12': `Notes ${getTextDate(-5)}`,
  'fileTree.13': `Notes ${getTextDate(-1)}`,
  'fileTree.102': `SatCell`,
  'fileTree.200': `Cyber Detector`,
  'fileTree.201': `Cyber Counter`,
  'fileTree.205': `Fiche: JMX`,
  'fileTree.302': `Suspects`,
  'fileTree.303': `Judith Rivoli`,
  'fileTree.304': `Nils Brown`,
  'fileTree.305': `José Paquin`,
  'fileTree.306': `Ariane Estapolo`,
  'fileTree.309': `José Paquin`,
  'fileTree.310': `Interrogatoire`,
  'fileTree.311': `Questions préliminaires`,
  'fileTree.312': `Interrogatoire`,
  'fileTree.314': `Arrestation`,
  'fileTree.315': `Arrestation 2`,
  'fileTree.316': `Fiançailles`,
  'fileTree.317': `Amant`,
  'fileTree.318': `Message Crypté José Paquin`,
  'fileTree.340': `Liste des vols`,
  'fileTree.341': `Convertisseur binaire`,
  'fileTree.400': `Concerts`,
  'fileTree.461': `Effets Vocaux`,
  'fileTree.480': `Carte`,
  'fileTree.449': `Les Cloches du Monde`,
  'fileTree.450': `Almudena, Madrid, Espagne`,
  'fileTree.451': `St. Agatha, Kalvarienberg, Allemagne`, //Alte Kirche Alendorf
  'fileTree.452': `Notre-Dame-de-l'Assomption, Cuzco, Pérou`,
  'fileTree.453': `BigBen, Londres, Angleterre`,
  'fileTree.454': `Cathédrale de Santander, Santander, Espagne`,
  'fileTree.455': `Santi Apostoli, Florence, Italie`,
  'fileTree.456': `St.Mary Le Bow, Londres, Angleterre`,
  'fileTree.457': `Église St.Pauls, Londres, Angleterre`,
  'fileTree.458': `Westerkerk, Amsterdam, Pays-Bas`,
  'fileTree.460': `Enregistrements de M`,
  'fileTree.465': `M Enregistrement 1`,
  'fileTree.462': `M Enregistrement 2`,
  'fileTree.463': `M Enregistrement 3`,
  'fileTree.464': `M Enregistrement 4`,
  'menuItem.0': `Tableau de bord`,
  'menuItem.1': `MISSIONS`,
  'menuItem.2': `Une trahison explosive`,
  'menuItem.4': `Un jeu glaçant`,
  'menuItem.6': `Verrouillé`,
  'menuItem.105': `Apps`,
  'menuItem.100': `Tableau de bord`,
  'menuItem.103': `Fichiers M27`,
  'menuItem.106': `SatCell`,
  'menuItem.107': `CyberCounter`,
  'menuItem.104': `Objectifs`,
  'menuItem.101': `Secourir Malory`,
  'menuItem.102': `Contrer le JMX`,
  'menuItem.110': `Trouver le contact`,
  'menuItem.111': `Retrouver le vol en danger`,
  'menuItem.112': `Retrouver M`,
  'menuItem.113': `Qui est M?`,
  'menuItem.114': `Quitter`,
  'menuItem.115': `Trouver la compagnie`,
  'menuItem.200': `Apps`,
  'menuItem.201': `Tableau de bord`,
  'menuItem.210': `Indices`,
  'menuItem.205': `Objectifs`,
  'menuItem.202': `Infiltrer le dossier crypté`,
  'menuItem.203': `Pirater MissilGouv`,
  'menuItem.204': `Empêcher le décollage`,
  'menuItem.206': `Neutraliser le missile`,
  'helpPage.skip': `Passer cette étape`,
  'helpPageE1.title': `Compléments d'enquête`,
  'helpPageE1.subtitle': `À lire lorsque vous n'avez plus l'ombre d'une piste...`,
  'helpPageE1.hint1.title': `Accéder à l'ordinateur de Vilamo`,
  'helpPageE1.hint1.content': `Dans la fiche de Vilamo, il est indiqué qu'il utilise le symbole de son grade comme signature. Regardez l'image de sa carte de transport: vous verrez une forme jaune sur fond rouge. C'est le symbole que vous pourrez utiliser pour décrypter le dossier.`,
  'helpPageE1.hint2.title': `Pirater MissilGouv`,
  'helpPageE1.hint2.content': `<p>Dans le dossier "Ordinateur de Vilamo", vous trouverez un document "Note Vilamo Part 1/2" ou "Note Vilamo Part 2/2".
                  Comme vous êtes au moins de agents, l'un doit avoir le premier, et l'autre le deuxième.
                  Écoutez ces enregistrements: ils se complètent.
                  Ainsi, le premier enregistrement vous donne 3, *, 2, *, 6 et *.
                  Le deuxième vous donne *, 5, *, 4, * et 1.
                  En combinant les deux, vous aurez 3, 5, 2, 4, 6 et 1.
                </p>
                <p>
                  Maintenant allez tous dans l'application MissilGouv. L'un doit pouvoir cliquer sur les boutons, l'autre peut lire des chiffres devant chaque bouton.
                  Ce sera à celui qui peut lire les chiffres d'indiquer à l'autre agent sur quels boutons appuyer.
                </p>
                <p>
                  La séquence de bouton sera alors:
                  3. Provoquer les actions barrières
                  5. Équilibrer le niveau de Kérozène
                  2. Baliser les coordonnées extérieures
                  4. Répéter le code de dislocation
                  6. Stabiliser les détecteurs
                  1. Transfert données exploitation
                </p>`,
  'helpPageE1.hint3.title': `Surcharger la télémétrie`,
  'helpPageE1.hint3.content': `<p>
                  La missile a décollé ! Allez dans le panneau de télémétrie depuis MissilGouv.
                  L'un d'entre vous a un mot, l'autre une zone de texte. Il faudra transmettre les mots rapidement pour réussir à surcharger la télémétrie.
                  Mais attention: les rôles s'inversent parfois!
                </p>`,
    'helpPageE2.title': `Compléments d'enquête`,
    'helpPageE2.subtitle': `À lire lorsque vous n'avez plus l'ombre d'une piste...`,
    'helpPageE2.hint1.title': `Retrouver Malory - Quartier`,
    'helpPageE2.hint1.content': `Malory a laissé 3 vidéo-notes, écoutez les pour retrouver dans quel quartier elle se trouve.`,
    'helpPageE2.hint2.title': `Retrouver Malory - Rue`,
    'helpPageE2.hint2.content': `Malory se trouve à Lambeth. Observez les appels téléphoniques de la zone grâce à SatCell. Dans ce quartier, il semble y avoir dans les dernières minutes une forte concentration d'appels dans une rue spécifique.`,
    'helpPageE2.hint3.title': `Retrouver Malory - Solution`,
    'helpPageE2.hint3.content': `On peut observer une forte concentration dans les dernières minutes à Newport street.`,
    'helpPageE2.hint4.title': `Contrer le JMX - Trouver la fréquence`,
    'helpPageE2.hint4.content': `Les JMX utilisent une fréquence particulière: essayez de la retrouver dans la fiche du JMX. Vous pourrez entrer cette fréquence dans CyberDetector`,
    'helpPageE2.hint5.title': `Contrer le JMX - Identifier la voiture piégée`,
    'helpPageE2.hint5.content': `<p>Une fois la fréquence 615.55 entrée, dans CyberDetector, l'un des joueurs verra des points sur la carte, et l'autre verra les 4 directions cardinales.</p>
                <p>En cliquant sur ces directions, celui-ci peut entendre dans quelle direction se trouve le JMX par rapport au cercle bleu au centre de l'écran du joueur qui a la carte.</p>`,
    'helpPageE2.hint6.title': `Contrer le JMX - Désactiver le JMX`,
    'helpPageE2.hint6.content': `<p>Grâce à la coopération des deux agents, vous aurez trouvé la voiture sur l'autoroute, ayant pour adresse IP: "201.204.93.168".</p>
                <p>En entrant cette adresse sur "Cyber Counter", vous serez connecté. Vous verrez alors une liste de symbole qui est la même pour toute votre équipe, mais certains sont masqués pour vous.</p>
                <p>Vous aurez aussi vos actions personnelles et vous êtes les seuls à les avoir.</p>
                <p>Il faudra décrire aux autres agents quels sont les formes de la séquence, dans l'ordre.</p>
                <p>Mais faites-vite: vous avez un temps limité avant que la séquence ne se remette à zéro.</p>`,
    'helpPageE2.hint7.title': `Identifier le vol en danger - Trouver le contact`,
    'helpPageE2.hint7.content': `Dans le dossier M27, vous trouverez la liste des suspects. L'un d'eux a travaille comme contrôleur aérien.`,
    'helpPageE2.hint8.title': `Identifier le vol en danger - Analyser José`,
    'helpPageE2.hint8.content': `<p>Dans la partie "Questions préliminaires" de l'interrogatoire, José fait un geste particulier quand il ment.</p>
                <p>Peut-être saurez-vous vérifier certaines des réponses grâce aux éléments de l'enquête.</p>`,
    'helpPageE2.hint9.title': `Identifier le vol en danger - Discerner le mensonge`,
    'helpPageE2.hint9.content': `<p>En y regardant de plus près, José se gratte le menton à chaque fois qu'il ment!</p>
                <p>Dans la vidéo "interrogatoire", il répond à une question concernant des compagnies aériennes qui révèle quelque chose.</p>
                <p>Par ailleurs, vous trouverez un message crypté qui semble contenir la destination du vol.</p>`,
    'helpPageE2.hint10.title': `Identifier le vol en danger - Solution`,
    'helpPageE2.hint10.content': `<p>En combinant la compagnie aérienne Corsair révélée par José et la destination du vol (Montréal - YUL) du message décrypté, vous pourrez retrouver le vol dans la liste des vols en cours.</p>
                <p>Le vol est bien: GK 452</p>`,
    'helpPageE2.hint11.title': `Retrouver M - Ville et quartier`,
    'helpPageE2.hint11.content': `L'un des messages de M semble avoir un bruit de fond: on entend une église retentir. saurez-vous la retrouver ?`,
    'helpPageE2.hint12.title': `Retrouver M - Qui est M?`,
    'helpPageE2.hint12.content': `<p>M est bien dans Londres, à Cheapside. Et c'est l'un des 3 agents du SIG présents là bas: Pennyworth, Martin ou Malory.</p>
                <p>En appliquant des effets sur leurs voix, essayer d'identifier lequel a la voix la plus proche de M.</p>`,
    
}