import { combineReducers } from 'redux'
import { intlReducer } from 'react-intl-redux' 

import mainInterface from './mainInterface'
import additionalPanels from './additionalPanels'
import variables from 'discapecommon/reducers/variables'
import enigmas from 'discapecommon/reducers/enigmas'
import visibilityFilter from './visibilityFilter'
import connection from './connection'
import languages from 'discapecommon/reducers/languages'
import users from 'discapecommon/reducers/users'
import sessionUsers from 'discapecommon/reducers/sessionUsers'
// Medias
import mediaPlayer from './mediaPlayer'
import medias from 'discapecommon/reducers/medias'
import playlists from 'discapecommon/reducers/playlists'
import videoConference from './videoConference.reducer.js'
// Triggers
import triggerPanel from '../triggerEditor/reducers/triggerPanel'
import triggers from 'discapecommon/reducers/triggers'
import functions from 'discapecommon/reducers/functions'
// Other
import chatMessages from 'discapecommon/reducers/chatMessages'
import chatConversations from 'discapecommon/reducers/chatConversations'
import instances from 'discapecommon/reducers/instances'
import sessionSelector from './sessionSelector'
import missionViewer from './missionViewer'
import session from './session'
import gameFlow from './gameFlow'
import fileTree from './fileTree'
import appData from './appData'
import user from './user'
import sideMenu from './sideMenu'
import locales from './locales'
import settings from './settings'
import mainDashboard from './mainDashboard'
import endingPanel from './endingPanel'
import navBar from './navBar'
// Login
import alerts from './alerts'
import registration from './registration'
import dataSheet from './dataSheet.reducer'
// Mission
import missionArticles from './missionArticles'
import missionModels from './missionModels'
import notifications from './notifications'

const reducer = combineReducers({
  alerts,
  navBar,
  additionalPanels,
  registration,
  dataSheet,
  endingPanel,
  chatMessages,
  notifications,
  chatConversations,
  missionArticles,
  missionModels,
  user,
  sessionUsers,
  mainDashboard,
  settings,
  instances,
  sessionSelector,
  missionViewer,
  session,
  appData,
  gameFlow,
  fileTree,
  enigmas,
  users,
  visibilityFilter,
  mainInterface,
  connection,
  variables,
  sideMenu,
  locales,
  intl: intlReducer,
  languages,
// Medias
  medias,
  mediaPlayer,
  videoConference,
  playlists,
// Trigger system:
  triggerPanel,
  triggers,
  functions,
})

export default reducer
