// En attendant d'avoir l'intégration de la partie trigger, le jeu se déroule avec le gameFlow

/*
 * Game Flow
 */

import treasonReducer from '../missions/treason/treason.reducer'
import coldGameReducer from '../missions/coldGame/coldGame.reducer'
import {cltStartMission} from '../actions/missionActions'
import { stopAllAudio, videoConferenceStopAll} from 'discapecommon/actions/mediaActions'
import { debugShowCore } from 'discapecommon/actions/index'
import { upForceLoadStage } from 'discapecommon/actions/gameFlowActions'

const gameFlow = (state = {startDate: 0}, action) => {
  switch (action.type) {
    case 'PUSH_SESSION_STATE':
      return {...action.state, startDate: action.state?.startDate};
    case 'CLT_LOAD_MISSION':
      return {...state, missionKey: action.missionKey, status: 'LOADED'};
    case 'PUSH_START_ACTIVITY':
    case 'CLT_TRIGGER_START_MISSION':
      action.asyncDispatch(cltStartMission(action.state))
      const startDate = action.state?.startDate ? action.state?.startDate : Date.now();
      return {...state, startDate, endDate:action.state?.endDate};
    case 'CLT_MISSION_CLOSE_SESSION':
      action.asyncDispatch(stopAllAudio())
      action.asyncDispatch(videoConferenceStopAll())
      return {};
    case 'CLT_MISSION_END':
      return {...state, endDate: state.endDate ? state.endDate : Date.now()};
    case 'PUSH_CHAT_MESSAGE':
      if(action.message?.includes('code ')) {
        var access = action.message.split(' ')
        switch(access[1]) {
          case 'loadstage':
            action.asyncDispatch(upForceLoadStage(parseInt(access[2])))
            break;
          case 'showcore':
            action.asyncDispatch(debugShowCore())
            break
        }
      }
  }


  switch(state.missionKey) {
    case 'treason': return treasonReducer(state, action); break;
    case 'coldGame': return coldGameReducer(state, action); break;
  }
  return state
}

export default gameFlow;