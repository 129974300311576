/*
 * Mission Data: name, id
 */
import {openAdditionalPanel, setContentPanel} from 'discapecommon/actions/index'
import {missionTypes} from 'discapecommon/consts/missionTypes'
import {viewMission} from '../actions/missionActions'
import {getCheckoutSession} from '../actions/catalogActions'
import {log} from '../utils'
import browserHistory from 'discapecommon/browserHistory'
import navigationManager from '../core/navigationManager'
/*
Presentation de la mission

lockStatus représente si la mission a été acheté ou non
*/

const defaultState = {}

const missionViewer = (state = defaultState, action) => {
  switch (action.type) {
    case missionTypes.VIEW_MISSION:     
      console.log('Reducing view mission', '/mission/'+action.missionKey) 
      action.asyncDispatch(setContentPanel('MISSION_VIEWER'));

      if(state.isPendingBuy && action.lockStatus == false) {
        action.asyncDispatch(getCheckoutSession([{missionKey:action.missionKey, quantity:1}]))
      }

      return {...state, missionKey: action.missionKey, lockStatus:action.lockStatus};
    case 'MISSION_REGISTER_IS_PENDING_BUY':
      return {...state, isPendingBuy: true}
    case 'REFRESH_CURRENT_PAGE':
      if(state.missionKey) {
        action.asyncDispatch(viewMission(state.missionKey))
      }
      return state;
    default:
      return state
  }
}

export default missionViewer
