import {openAdditionalPanel, closeAdditionalPanel} from 'discapecommon/actions/index'
import {setChannelStatus, playAudio, stopAudio, videoConferenceEnded} from 'discapecommon/actions/mediaActions'
import {channels} from 'discapecommon/consts/channels';
import { OCCURING_EVENT_TYPES } from 'discapecommon/consts/occuringEventTypes'

const defaultState = {
  preloads: [],
}

/*
Il faudrait implémenter un système de liste de lecture pour une suite de vidéo
=> Un peu compliqué de faire ça avec de la logique
*/

const videoConferenceReducer = (state = {}, action) => {
  switch (action.type) {
    case 'START_VIDEO_CONFERENCE':
      action.asyncDispatch(openAdditionalPanel('PLAYER_VIDEO'));
      action.asyncDispatch(setChannelStatus(0, 'PAUSE'));
      if(action.showPickUp)
        action.asyncDispatch(playAudio(2000, 100, channels.SFX));
      return {...state, 
        isStarted: true,
        mediaId:action.mediaId, 
        operation:action.operation,
        keepUp: action.options?.keepUp,
        showPickUp: action.options?.showPickUp,
        options: action.options
      };
    case 'UPDATE_VIDEO_CONFERENCE':
      if(!state.isStarted) {
        action.asyncDispatch(openAdditionalPanel('PLAYER_VIDEO'));
        action.asyncDispatch(setChannelStatus(0, 'PAUSE'));
        action.asyncDispatch(playAudio(2000, 100, channels.SFX));
      }
      return {...state,
        isStarted: true,
        mediaId:action.mediaId,
        operation:action.operation,
        keepUp: action.keepUp,
      }
    case 'VIDEO_CONFERENCE_PRELOAD':
      return {...state,
        preloads: [...state.preloads, action.mediaId]
      }
    case 'VIDEO_CONFERENCE_ENDED':
      if(state.keepUp && !action.force) {
        return state;
      }
      action.asyncDispatch(closeAdditionalPanel('PLAYER_VIDEO'));
      action.asyncDispatch(setChannelStatus(0, 'PLAY'));
      action.asyncDispatch(stopAudio(2000, channels.SFX)) // Stop Skype sound
      return {...state, isStarted: false};
    case OCCURING_EVENT_TYPES.VIDEO_CONFERENCE_END_ICON_CLICK:
      action.asyncDispatch(videoConferenceEnded(action.media, true));
      return state;
    case 'CLT_SET_VIDEO_CONFERENCE_MUTED': 
      return {...state, isMuted:action.isMuted}
    case 'VIDEO_CONFERENCE_PICKED_UP':
      action.asyncDispatch(stopAudio(2000, channels.SFX)) // Stop Skype sound
      return state;
    case 'CLT_CHECK_LEAVE_SESSION':
    case 'VIDEO_CONFERENCE_CLOSE_ALL':
      action.asyncDispatch(closeAdditionalPanel('PLAYER_VIDEO'));
      return {};
    default:
      return state;
  }
}

export default videoConferenceReducer