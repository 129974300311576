import treasonPoster from '../../assets/missions/treason/images/Une_Trahison_Explosive_Illustration_1000.jpg';
import bamboozledPoster from '../../assets/missions/coldGame/images/Menes-en-bateau_Illustration_1000.jpg';
import lockedMission from '../../assets/images/lock1000.jpg'

const missionStatus = {
  LIVE: 10,
  WIP: 1,
  DRAFT: 0,
}

export default [
  {
    key:'treason',
    status: missionStatus.LIVE,
    poster: treasonPoster,
    support:'live',
    category:'escape',
    nbPlayerMin:2,
    nbPlayerMax:4,
    scaryFactor:'3/5',
    difficulty:'2/5',
    price:0,
    ageMin:12,
    duration:20,
    isPlayableSolo: true,
    isPlayableMulti: true,
  },
  {
    _id:'5eb4610829588720d89f9695',
    key:'coldGame',
    status: missionStatus.LIVE,
    poster: bamboozledPoster,
    support:'live',
    category:'escape',
    nbPlayerMin:2,
    nbPlayerMax:4,
    scaryFactor:'3/5',
    difficulty:'2/5',
    price:12,
    ageMin:12,
    duration:60,
    hasTrailer: true,
    trailerSrcFr: 'https://discape-production.s3.us-west-002.backblazeb2.com/E2_Seq001_Fr_1080p.mp4',
    trailerSrcEn: 'https://discape-production.s3.us-west-002.backblazeb2.com/E2_Seq001_Fr_1080p.mp4',
    isPlayableSolo: true,
    isPlayableMulti: true,
  },
  {
    _id:'5eb4610829588720d89f9695',
    key:'chase',
    model:'chase',
    mode:'solo',
    status: missionStatus.WIP,
    poster: lockedMission,
    support:'live',
    category:'escape',
    nbPlayerMin:2,
    nbPlayerMax:4,
    scaryFactor:'3/5',
    difficulty:'2/5',
    price:12,
    ageMin:12,
    duration:20,
    isPlayableSolo: true,
    isPlayableMulti: true,
  },
]