import ExtendableError from 'es6-error';

export class IoError extends ExtendableError {
  constructor({ request, response }) {
    const message = `Socket.io response error.  Expected type '${request.type}' but got '${response.type}'`;
    super(message);
  }
}

export class IoTimeoutError extends ExtendableError {
  constructor(request) {
    const message = `Timeout error. If the problem persists, please contact us at info@discapegame.com`;
    super(message);
    console.log('Timeout Error: '+request);
  }
}