import { userTypes } from 'discapecommon/consts/userTypes';
import * as userService from '../services';
import { openAdditionalPanel, closeAdditionalPanel } from 'discapecommon/actions/index';
import { alertActions } from 'discapecommon/actions/alertActions';
import browserHistory from 'discapecommon/browserHistory'
import { alertConstants } from 'discapecommon/consts/alertConsts';

export const userActions = {
  login,
  openLoginRetrieval,
  logOut,
  register,
  verifyToken,
  update,
  updatePassword,
  retrievePassword,
  verifyResetToken,
  setNewPassword,
  getAll,
  delete: _delete
};

function login(user) {
  return dispatch => {
    dispatch(request(user.email));

    userService.login(user)
    .then(
      user => { 
        dispatch(success(user));
        dispatch(closeAdditionalPanel('LOG_IN'));
      },
      error => {
        console.log('error ',error, error.message)
        dispatch(failure(error));
        dispatch(alertActions.error(error.message));
      }
      );
  };
 
  function request(user) { return { type: userTypes.USERS_LOGIN_REQUEST, user } }
  function success(user) { return { type: userTypes.USERS_LOGIN_SUCCESS, user } }
  function failure(alert) { return { type: userTypes.USERS_LOGIN_FAILURE, alert } }
}

function verifyToken() {
  return dispatch => {
    userService.verifyToken()
    .then(
      response => { 
        dispatch(success(response.user));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function success(user) { return { type: userTypes.USERS_LOGIN_SUCCESS, user } }
  function failure(alert) { return { type: userTypes.USERS_VERIFY_TOKEN_FAILURE, alert } }
}

function logOut() {
  userService.logOut();
  return { type: userTypes.USERS_LOG_OUT };
}


function register(user) {
  return dispatch => {
    dispatch(request(user));

    userService.register(user)
    .then(
      user => { 
        dispatch(success(user));
        dispatch(alertActions.success('Registration successful'));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error.message));
      }
      );
  };

  function request(user) { return { type: userTypes.USERS_REGISTER_REQUEST, user } }
  function success(user) { return { type: userTypes.USERS_REGISTER_SUCCESS, user } }
  function failure(error) { return { type: userTypes.USERS_REGISTER_FAILURE, error } }
}

function getAll() {
  return dispatch => {
    dispatch(request());

    userService.getAll()
    .then(
      users => dispatch(success(users)),
      error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: userTypes.USERS_GETALL_REQUEST } }
  function success(users) { return { type: userTypes.USERS_GETALL_SUCCESS, users } }
  function failure(error) { return { type: userTypes.USERS_GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    userService._delete(id)
    .then(
      user => dispatch(success(id)),
      error => dispatch(failure(id, error.toString()))
      );
  };

  function request(id) { return { type: userTypes.USERS_DELETE_REQUEST, id } }
  function success(id) { return { type: userTypes.USERS_DELETE_SUCCESS, id } }
  function failure(id, alert) { return { type: userTypes.USERS_DELETE_FAILURE, id, alert } }
}

function update(user) {
  return dispatch => {
    dispatch(request(user));
    userService.update(user)
    .then(
      () => dispatch(success(user)),
      error => dispatch(failure(error))
      );
  };

  function request() { return { type: userTypes.USERS_UPDATE_REQUEST } }
  function success(user) { return { type: userTypes.USERS_UPDATE_SUCCESS, user } }
  // function failure(alert) { return { type: userTypes.USERS_UPDATE_FAILURE } }
  function failure(alert) { return { type: alertConstants.ERROR, message: alert?.message } }
}

function updatePassword(passwords) {
  return dispatch => {
    dispatch(request());
    userService.updatePassword(passwords)
    .then(
      () => dispatch(success()),
      error => {
        dispatch(failure(error))
        dispatch(alertActions.error(error.message));
      }
      );
  };

  function request() { return { type: userTypes.USERS_UPDATE_PASSWORD_REQUEST } }
  function success() { return { type: userTypes.USERS_UPDATE_PASSWORD_SUCCESS } }
  function failure(alert) { return { type: userTypes.USERS_UPDATE_PASSWORD_FAILURE, alert } }
}

function openLoginRetrieval() {
  return dispatch => {
    dispatch(openAdditionalPanel('LOG_IN_RETRIEVAL'));
    dispatch(closeAdditionalPanel('LOG_IN'));
  }
}

function retrievePassword(email) {
  return dispatch => {
    dispatch(request()); // Inform front of the request
    userService.retrievePassword(email) // Ask backend to send an email to retrieve password
    .then(
      () => dispatch(emailSent()),
      error => dispatch(failure(error))
      );
  };

  function request() { 
    console.log('type request inside: ', userTypes.USERS_RETRIEVE_PASSWORD_REQUEST);
    return { type: userTypes.USERS_RETRIEVE_PASSWORD_REQUEST } 
  }
  function emailSent() { return { type: userTypes.USERS_RETRIEVE_PASSWORD_EMAIL_SENT } }
  function failure(alert) { return { type: userTypes.USERS_RETRIEVE_PASSWORD_FAILURE, alert } }
}

function verifyResetToken(token) {
  return dispatch => {
    dispatch(openAdditionalPanel('LOG_IN_RETRIEVAL'))
    dispatch(request());
    userService.verifyResetToken(token)
    .then(
      () => {
        dispatch(success())
      },
      error => dispatch(failure(error))
      );
  };

  function request() { return { type: userTypes.USERS_VERIFY_RESET_TOKEN_REQUEST } }
  function success() { return { type: userTypes.USERS_VERIFY_RESET_TOKEN_SUCCESS } }
  function failure(alert) { 
    if(alert?.message == 'jwt malformed')
      alert.message = 'Invalid Token'
    return { type: userTypes.USERS_VERIFY_RESET_TOKEN_FAILURE, alert } 
  }
}

function setNewPassword(password) {
  return dispatch => {
    dispatch(request());
    userService.setNewPassword(password)
    .then(
      () => {
        dispatch(success())
        browserHistory.push('/')
      },
      error => dispatch(failure(error))
      );
  };

  function request() { return { type: userTypes.USERS_SET_NEW_PASSWORD_REQUEST } }
  function success() { return { type: userTypes.USERS_SET_NEW_PASSWORD_SUCCESS } }
  function failure(alert) { return { type: userTypes.USERS_SET_NEW_PASSWORD_FAILURE, alert } }
}
