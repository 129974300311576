import {cltFileTreeLoadTree} from 'discapecommon/actions/appActions'
import {setCurrentScene, closeAdditionalPanel, setLinkStatus,
  triggerCustomNetworkEvent, goHome, upTriggerCustomEvent} from 'discapecommon/actions/index'
import {openEnigma} from 'discapecommon/actions/enigmaActions'
import {playMedia, setMediaList, playAudio, stopAudio, setFilesStatus, stopAllAudio,
  startVideoConference} from 'discapecommon/actions/mediaActions'
import {closeDrawer, openEndingPanel} from 'discapecommon/actions/layoutActions'
import {navBarSetCountDownId, navBarSetEndCondition, navBarCountDownSetStatus, navBarSetObjective} from 'discapecommon/actions/navBarActions'
import {countDownCreate, countDownSetStatus} from 'discapecommon/actions/countDownActions'
import {viewMission, cltMissionEnd} from '../../actions/missionActions'

import Malory0 from '../../../assets/missions/coldGame/voicesEffects/Malory0.mp3'
import Malory1 from '../../../assets/missions/coldGame/voicesEffects/Malory1.mp3'
import Malory2 from '../../../assets/missions/coldGame/voicesEffects/Malory2.mp3'
import Martin0 from '../../../assets/missions/coldGame/voicesEffects/Martin0.mp3'
import Martin1 from '../../../assets/missions/coldGame/voicesEffects/Martin1.mp3'
import Martin2 from '../../../assets/missions/coldGame/voicesEffects/Martin2.mp3'
import Penny0 from '../../../assets/missions/coldGame/voicesEffects/Penny0.mp3'
import Penny1 from '../../../assets/missions/coldGame/voicesEffects/Penny1.mp3'
import Penny2 from '../../../assets/missions/coldGame/voicesEffects/Penny2.mp3'
// Bells
import Almudena from '../../../assets/missions/coldGame/bells/Almudena-Madrid-Spain.mp3'
import AlteKirche from '../../../assets/missions/coldGame/bells/Alte Kirche Alendorf-Kalvarienberg-Germany.mp3'
import Basilica from '../../../assets/missions/coldGame/bells/BasilicaDeLaCathedral-Cusco-Peru.mp3'
import Bigben from '../../../assets/missions/coldGame/bells/BigBen-Lodon-England.mp3'
import Santander from '../../../assets/missions/coldGame/bells/Santander Cathedral-Santander-Spain.mp3'
import SantiApostoli from '../../../assets/missions/coldGame/bells/Santi Apostoli-Florence-Italy.mp3'
import StMary from '../../../assets/missions/coldGame/bells/St.Mary Le Bow-London-England.mp3'
import StPaul from '../../../assets/missions/coldGame/bells/St.Pauls Church-London-England.mp3'
import Westerkerk from '../../../assets/missions/coldGame/bells/Westerkerk-Amsterdam-Netherlands.mp3'
// M
import M1 from '../../../assets/missions/coldGame/voices/M1.mp3'
import M2 from '../../../assets/missions/coldGame/voices/M2.mp3'
import M3 from '../../../assets/missions/coldGame/voices/M3.mp3'
import M4 from '../../../assets/missions/coldGame/voices/M4.mp3'
// Various
import {channels} from 'discapecommon/consts/channels';
// Jose Paquin
import JosePaquinArrest from '../../../assets/missions/coldGame/images/JosePaquinArrest.jpg'
import JosePaquinFiancailles from '../../../assets/missions/coldGame/images/JosePaquinFiancailles.jpg'
import JosePaquinAmant from '../../../assets/missions/coldGame/images/JosePaquinAmant.jpg'
import JosePaquinFile from '../../../assets/missions/coldGame/images/JosePaquinFile.jpg'

import ringtone from '../../../assets/audios/Ringtone.mp3';
import { isTestEnv } from '../../utils'

// Answer: 201.204.93.168

var hideVideos = false;
var activateMusic = true;
var isDev = false;

// hideVideos = true;
// activateMusic = false;
// isDev = true;

var fileHost = 'https://discape-production.s3.us-west-002.backblazeb2.com/'


if(isTestEnv(['localhost']))
  fileHost = '/S3/'
// Audio
const spyMusic = fileHost+ 'SpyMusic-30db.mp3'

// Videos
const V001Fr = fileHost+ 'E2_Seq001_Fr_1080p.mp4'
const V001En = fileHost+ 'E2_Seq001_En_1080p.mp4'
const V104Fr = fileHost+ 'E2_Seq104_Fr_1080p.mp4'
const V104En = fileHost+ 'E2_Seq104_En_1080p.mp4'
const V105Fr = fileHost+ 'E2_Seq105_Fr_1080p.mp4'
const V105En = fileHost+ 'E2_Seq105_En_1080p.mp4'
const V106Fr = fileHost+ 'E2_Seq106_Fr_1080p.mp4'
const V106En = fileHost+ 'E2_Seq106_En_1080p.mp4'
const V199Fr = fileHost+ 'E2_Seq199_Fr_1080p.mp4'
const V299Fr = fileHost+ 'E2_Seq299_Fr_1080p.mp4'
const V309Fr = fileHost+ 'E2_Seq309_Fr_1080p.mp4'
const V320Fr = fileHost+ 'E2_Seq320_Fr_1080p.mp4'
const V320En = fileHost+ 'E2_Seq320_En_1080p.mp4'
const V330Fr = fileHost+ 'E2_Seq330_Fr_1080p.mp4'
const V330En = fileHost+ 'E2_Seq330_En_1080p.mp4'
const V399Fr = fileHost+ 'E2_Seq399_Fr_1080p.mp4'
const V419Fr = fileHost+ 'E2_Seq419_Fr_1080p.mp4'
const V490Fr = fileHost+ 'E2_Seq490_Fr_1080p.mp4'
const V495Fr = fileHost+ 'E2_Seq495_Fr_1080p.mp4'

const filesColdGame = [
    {src: V001Fr, localeSrc: {en: V001En, fr: V001Fr}, mediaType:'VIDEO', id:1, name: 'Joe'},
    {src: V104Fr, localeSrc: {en: V104En, fr: V104Fr}, mediaType:'VIDEO', id:104, name: 'VideoNode 1 - Malory'},
    {src: V105Fr, localeSrc: {en: V105En, fr: V105Fr}, mediaType:'VIDEO', id:105, name: 'VideoNode 2 - Malory'},
    {src: V106Fr, localeSrc: {en: V106En, fr: V106Fr}, mediaType:'VIDEO', id:106, name: 'VideoNode 3 - Malory'},

    {src: spyMusic, mediaType: 'AUDIO', id: 4, name: 'spyMusic'},
    // Voice Effects
    {src: Malory0, mediaType: 'AUDIO', id: 20, name: 'Malory 0'},
    {src: Malory1, mediaType: 'AUDIO', id: 21, name: 'Malory 1'},
    {src: Malory2, mediaType: 'AUDIO', id: 22, name: 'Malory 2'},
    {src: Martin0, mediaType: 'AUDIO', id: 23, name: 'Martin 2'},
    {src: Martin1, mediaType: 'AUDIO', id: 24, name: 'Martin 2'},
    {src: Martin2, mediaType: 'AUDIO', id: 25, name: 'Martin 2'},
    {src: Penny0, mediaType: 'AUDIO', id: 26, name: 'Penny 2'},
    {src: Penny1, mediaType: 'AUDIO', id: 27, name: 'Penny 2'},
    {src: Penny2, mediaType: 'AUDIO', id: 28, name: 'Penny 2'},
    {src: V199Fr, mediaType: 'VIDEO', id: 199, name: 'Newport Street'},
    // Jose Paquin
    {src: JosePaquinArrest, mediaType: 'IMAGE', id: 311, localeKey: 'fileTree.315'},
    {src: JosePaquinFiancailles, mediaType: 'IMAGE', id: 312, localeKey: 'fileTree.316'},
    {src: JosePaquinAmant, mediaType: 'IMAGE', id: 313, localeKey: 'fileTree.317'},
    {src: JosePaquinFile, mediaType: 'IMAGE', id: 314, localeKey: 'fileTree.314'},
    {src: V299Fr, mediaType: 'VIDEO', id: 325, name: 'M prochain defi aérien'},
    {src: V309Fr, mediaType: 'VIDEO', id: 321, name: 'Joe retrouve José'},
    {src: V320Fr, localeSrc: {en: V320En, fr: V320Fr}, mediaType: 'VIDEO', id: 319, localeKey: 'fileTree.311'}, //name: 'José Paquin Préliminaire'
    {src: V330Fr, localeSrc: {en: V330En, fr: V330Fr}, mediaType: 'VIDEO', id: 320, localeKey: 'fileTree.312'}, //name: 'José Paquin Interrogatoire'
    {src: V399Fr, mediaType: 'VIDEO', id: 322, name: 'Fin avion; annonce police'},
    {src: V419Fr, mediaType: 'VIDEO', id: 323, name: 'Un des 3 agents'},
    {src: V490Fr, mediaType: 'VIDEO', id: 324, name: `C'est Malory - Success`},
    {src: V495Fr, mediaType: 'VIDEO', id: 326, name: `C'est Pennyworth - Failure`},
    // Bells
    {src: Almudena, mediaType: 'AUDIO', id: 450, localeKey: 'fileTree.450'}, //name: 'Almudena-Madrid-Spain'
    {src: AlteKirche, mediaType: 'AUDIO', id: 451, localeKey: 'fileTree.451'}, //name: 'Alte Kirche Alendorf-Kalvarienberg-Germany'
    {src: Basilica, mediaType: 'AUDIO', id: 452, localeKey: 'fileTree.452'}, //name: 'BasilicaDeLaCathedral-Cusco-Peru'
    {src: Bigben, mediaType: 'AUDIO', id: 453, localeKey: 'fileTree.453'}, //name: 'BigBen-Lodon-England'
    {src: Santander, mediaType: 'AUDIO', id: 454, localeKey: 'fileTree.454'}, //name: 'Santander Cathedral-Santander-Spain'
    {src: SantiApostoli, mediaType: 'AUDIO', id: 455, localeKey: 'fileTree.455'}, //name: 'Santi Apostoli-Florence-Italy'
    {src: StMary, mediaType: 'AUDIO', id: 456, localeKey: 'fileTree.456'}, // name: 'St.Mary Le Bow-London-England'
    {src: StPaul, mediaType: 'AUDIO', id: 457, localeKey: 'fileTree.457'}, //name: `St.Paul's Church-London-England`
    {src: Westerkerk, mediaType: 'AUDIO', id: 458, localeKey: 'fileTree.458'}, //name: 'Westerkerk-Amsterdam-Netherlands'
    // Enregistrement M
    {src: M1, mediaType: 'AUDIO', id: 501, localeKey: 'fileTree.465'}, //name: 'M Enregistrement 1'},
    {src: M2, mediaType: 'AUDIO', id: 502, localeKey: 'fileTree.462'}, //name: 'M Enregistrement 2'},
    {src: M3, mediaType: 'AUDIO', id: 503, localeKey: 'fileTree.463'}, //name: 'M Enregistrement 3'},
    {src: M4, mediaType: 'AUDIO', id: 504, localeKey: 'fileTree.464'}, //name: 'M Enregistrement 4'},
    {src: ringtone, mediaType: 'AUDIO', id: 2000, name: 'Ringtone'},
  ]

const loadBase = (state, action) => {
  action.asyncDispatch(setMediaList(filesColdGame));
  action.asyncDispatch(setCurrentScene('MISSION'));
  action.asyncDispatch(cltFileTreeLoadTree(10));
  action.asyncDispatch(navBarSetObjective(101))
  return state;
}

const startGame = (state, action) => {
  if(state.isPart000Started) {
    console.error('Part000 already started')
    return state;
  }

  loadBase(state, action);

  setTimeout(() => {
    if(!hideVideos) {
      action.asyncDispatch(startVideoConference(1, 'PLAY', {
        isSkippable: false, 
        showPickUp: true,
        keepUp: false,
      }));
    }
  }, 100)  

  // Test
  if(isDev) {
    action.asyncDispatch(setFilesStatus([200,201,205], 'VISIBLE'));
    action.asyncDispatch(setLinkStatus(110, 'UNSOLVED')); // Jose
    action.asyncDispatch(setLinkStatus(113, 'UNSOLVED')); // Lien vers Enigme identité M
    action.asyncDispatch(setFilesStatus([309,340,341], 'VISIBLE')); // Flight
    action.asyncDispatch(setLinkStatus(112, 'UNSOLVED')); // Lien vers Enigme quartier M
    action.asyncDispatch(setFilesStatus([449, 460, 480], 'VISIBLE')); // 400 (Concert)
  }
  
  return {...state, status: 'PLAYING', isPart000Started:true, stage: 0}
}

const part200Base = (state, action) => {
  action.asyncDispatch(setLinkStatus(101, 'SOLVED'));
  action.asyncDispatch(setLinkStatus(102, 'UNSOLVED')); // Counter JMX
  action.asyncDispatch(setLinkStatus(106, 'DISABLED')); // SatCell
  action.asyncDispatch(navBarSetObjective(102))
  action.asyncDispatch(countDownSetStatus(10, 'STOP'));
  action.asyncDispatch(setLinkStatus(107, 'DEFAULT'));  // Cybercounter
  action.asyncDispatch(setFilesStatus([200,201,205], 'VISIBLE'));
  action.asyncDispatch(setFilesStatus([102], 'DISABLED'));
  action.asyncDispatch(navBarSetCountDownId(11))
  action.asyncDispatch(navBarSetEndCondition('activity.coldGame.endCondition1'))
  return state;
}

const part200 = (state, action) => {
  if(state.isPart200Started)
    return state;
  setTimeout(() => {
      part200Base(state, action);
  }, 2000)
  action.asyncDispatch(startVideoConference(199, 'PLAY', { isSkippable: false }));
  return {...state, isPart200Started:true, stage: 200};
}


const part300Base = (state, action) => {
  action.asyncDispatch(setLinkStatus(102, 'SOLVED'));
  action.asyncDispatch(countDownSetStatus(11, 'STOP'));
  action.asyncDispatch(setLinkStatus(110, 'UNSOLVED')); // Enigme Jose
  action.asyncDispatch(navBarSetObjective(110))
  return state;
}

const part300 = (state, action) => {
  part300Base(state, action);
  if(!hideVideos)
    action.asyncDispatch(startVideoConference(325, 'PLAY', { isSkippable: false }));  // M Aerien
  return {...state, stage: 300};
}

const part310Base = (state, action) => {
  action.asyncDispatch(setLinkStatus(110, 'INVISIBLE'));
  action.asyncDispatch(setLinkStatus(115, 'UNSOLVED')); // Enigme Corsaire
  action.asyncDispatch(setLinkStatus(103, 'DISABLED')); 
  action.asyncDispatch(setLinkStatus(106, 'DISABLED')); 
  action.asyncDispatch(setLinkStatus(107, 'DISABLED')); 
  action.asyncDispatch(navBarSetObjective(111))
  action.asyncDispatch(setFilesStatus([309,341], 'VISIBLE'));
  action.asyncDispatch(setFilesStatus([1,200,201,205], 'DISABLED'));
  return state;
}
// They found Jose, need to find the flight number
const part310 = (state, action) => {
  if(state.isPart310Started && state.stage >= 310)
    return state;

  part310Base(state, action);

  if(!hideVideos)
      action.asyncDispatch(startVideoConference(321, 'PLAY', { isSkippable: false }));
  return {...state, isPart310Started:true, stage: 310};
}

// They found Jose, need to find the flight number
const part340 = (state, action) => {
  if(state.isPart340Started && state.stage >= 340)
    return state;

  action.asyncDispatch(setFilesStatus([340], 'VISIBLE')); // Liste des vols
  action.asyncDispatch(setFilesStatus([310], 'DISABLED')); // Dossier José
  action.asyncDispatch(setLinkStatus(111, 'UNSOLVED')); // Enigme GK 452
  action.asyncDispatch(setLinkStatus(115, 'INVISIBLE')); // Enigme Corsaire

  return {...state, isPart340Started:true, stage: 340};
}

const part400 = (state, action) => {
  if(state.isPart400Started && state.stage >= 400)
    return state;

  action.asyncDispatch(setLinkStatus(111, 'SOLVED'));
  action.asyncDispatch(countDownSetStatus(12, 'STOP'));
  action.asyncDispatch(setLinkStatus(112, 'UNSOLVED')); // Lien vers Enigme quartier M
  action.asyncDispatch(navBarSetObjective(112))
  action.asyncDispatch(setFilesStatus([449, 460, 480], 'VISIBLE')); // 400 (Concert) // 480: Gmaps
  action.asyncDispatch(setFilesStatus([309,340], 'DISABLED'));
  
  if(!hideVideos)
      action.asyncDispatch(startVideoConference(322, 'PLAY', { isSkippable: false }));
  return {...state, isPart400Started: true, stage: 400};
}

const part460 = (state, action) => {
  if(state.isPart460Started && state.stage >= 460)
    return state;

  action.asyncDispatch(setLinkStatus(112, 'SOLVED'));
  action.asyncDispatch(setLinkStatus(113, 'UNSOLVED')); // Lien vers Enigme identité M
  action.asyncDispatch(navBarSetObjective(113))
  action.asyncDispatch(setFilesStatus([461], 'VISIBLE'));

  if(!hideVideos)
    action.asyncDispatch(startVideoConference(323, 'PLAY', { isSkippable: false }));  // Retrouver M
  return {...state, isPart460Started: true, stage: 460};
}

const partVictory = (state, action) => {
  if(state.isPartVictoryStarted)
    return state;

  action.asyncDispatch(cltMissionEnd())
  action.asyncDispatch(setLinkStatus(113, 'SOLVED'));
  action.asyncDispatch(setLinkStatus(114, 'DEFAULT'));
  action.asyncDispatch(countDownSetStatus(13))
  if(!hideVideos)
      action.asyncDispatch(startVideoConference(324, 'PLAY', { isSkippable: false }));

  return {...state, isPartVictoryStarted: true, stage: 500};
}

const partDefeat = (state, action) => {
  if(state.isPartDefeatStarted)
    return state;

  action.asyncDispatch(cltMissionEnd())
  action.asyncDispatch(setLinkStatus(113, 'ERROR'));
  action.asyncDispatch(countDownSetStatus(13))
  if(!hideVideos)
    action.asyncDispatch(startVideoConference(326, 'PLAY', { isSkippable: false }));
  return {...state, isPartDefeatStarted:true, stage:490};
}

const part499FailTimeoutPolice = (state, action) => {
  action.asyncDispatch(openEndingPanel(2));
  action.asyncDispatch(cltMissionEnd())
  return {...state, stage: 499};
}

const part399FailTimeoutAvion = (state, action) => {
  action.asyncDispatch(openEndingPanel(7));
  action.asyncDispatch(cltMissionEnd())
  return {...state, stage: 399};
}

const part299FailTimeoutVoiture = (state, action) => {
  action.asyncDispatch(openEndingPanel(4))
  action.asyncDispatch(cltMissionEnd())
  return {...state, stage: 299};
}

const part199FailTimeoutMalory = (state, action) => {
  action.asyncDispatch(openEndingPanel(3))
  action.asyncDispatch(cltMissionEnd())
  return {...state, stage: 199};
}

const applyLoadStage = (state, action, stage) => {  
  loadBase(state, action);
  switch(stage) {
    case 500:  // Success
      return partVictory(state, action);
    case 499:  // Fail
      return part499FailTimeoutPolice(state, action);
    case 490:
      return partDefeat(state, action);
    case 460:
      var newState = part200Base(state, action);
      newState = part300Base(newState, action);
      newState = part310Base(newState, action);
      newState = part340(newState, action);
      newState = part400(newState, action);
      return part460(newState, action);
    case 400:
      var newState = part200Base(state, action);
      newState = part300Base(newState, action);
      newState = part310Base(newState, action);
      newState = part340(newState, action);
      return part400(newState, action);
    case 399: 
      return part399FailTimeoutAvion(state, action);
    case 310:
      part200Base(state, action);
      part300Base(state, action);
      return part310(state, action);
    case 300:
      part200Base(state, action);
      return part300(state, action);
    case 299: // Fail timeout voiture
      return part299FailTimeoutVoiture(state, action);
    case 200: // 201.204.93.168
    case 201:
      console.log('Loading Stage 200');
      return part200Base(state, action);
    case 199: // Timeout Malory
      return part199FailTimeoutMalory(state, action);
    case 100: 
      console.log('Loading Stage 100');
      return part100(state, action);
    default:
      return startGame(state, action);
  }
}

const gameFlowColdGame = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_STAGE':
      return applyLoadStage(state, action, action.stage)
      break;
    case 'CLT_START_MISSION':
      switch(action.state?.status) {
        case 'FAIL':
          return applyLoadStage(state, action, action.state?.currentStage);
        case 'SUCCESS':
          return partSuccess(state, action);
        default:
          return applyLoadStage(state, action, action.state?.currentStage);
      }
      return state;
    case 'VIDEO_CONFERENCE_ENDED':
      switch(action.media.id) {
        case 1:   // Fin introduction
          action.asyncDispatch(upTriggerCustomEvent('COLD_GAME_VIDEO_INTRO_COMPLETE'))
          action.asyncDispatch(navBarSetCountDownId(10))
          action.asyncDispatch(navBarSetEndCondition('activity.coldGame.endCondition2'))
          if(activateMusic) {
            action.asyncDispatch(playAudio(4, 100, channels.MUSIC));
          }
          if(!hideVideos) {
            action.asyncDispatch(openEnigma(13));
          }
          break;
        case 199:
          action.asyncDispatch(upTriggerCustomEvent('COLD_GAME_VIDEO_199_COMPLETE'))
          part200Base(state, action);
          break;
        case 325:
          action.asyncDispatch(upTriggerCustomEvent('COLD_GAME_VIDEO_325_COMPLETE'))
          action.asyncDispatch(navBarSetCountDownId(12))
          action.asyncDispatch(navBarSetEndCondition('activity.coldGame.endCondition3'))
          break;
        case 322:
          action.asyncDispatch(upTriggerCustomEvent('COLD_GAME_VIDEO_322_COMPLETE'))
          action.asyncDispatch(navBarSetCountDownId(13))
          action.asyncDispatch(navBarSetEndCondition('activity.coldGame.endCondition4'))
          break;
        case 324:   // Fin Malory et révélation 
          action.asyncDispatch(cltMissionEnd())
          action.asyncDispatch(openEndingPanel(1));
          break;
        case 326:   // Fin Martin et révélation 
          action.asyncDispatch(cltMissionEnd())
          action.asyncDispatch(openEndingPanel(2));
          break;
      }
      return state;
    case 'ENIGMA_SOLVED':
      switch(action.id) {
        case 0: return part200(state, action);
        case 4: return part310(state, action);  // Ils viennent de trouver Jose Paquin
        case 5: return part400(state, action);
        case 6: return part460(state, action);  // Ils donnent le quartier
        case 7: return partVictory(state, action);
        case 23: return part340(state, action);
      }
      return state;
    case 'ENIGMA_INVALID_ATTEMPT':
      switch(action.id) {
        case 7:
          return partDefeat(state, action) 
      }
      break;
    case 'DOWN_CUSTOM_EVENT':
      switch(action.key) {
        case 'startPart200': return part200(state, action);
        case 'startPart310': return part310(state, action);
        case 'startPart400': return part400(state, action);
        case 'startPart460': return part460(state, action);
        case 'startPartVictory': return partVictory(state, action);
        case 'startPartDefeat': return partDefeat(state, action);
      }
      return state;
    case 'TRIGGER_CUSTOM_EVENT': 
      switch(action.key) {
        case 'CYBER_COUNTER_SUCCESS':
          return part300(state, action);
        default:
          console.error('Unrecognized custom event: ',action)
          return state;
      }
    case 'CLT_LOAD_MISSION':
      return {...state, missionKey: action.missionKey};
      break;
    case 'COUNT_DOWN_TIMEOUT':
      switch(action.id) {
        case 10: // Malory
          return part199FailTimeoutMalory(state, action);
        case 11: // Voiture
          return part299FailTimeoutVoiture(state, action);
        case 12: // Avion
          return part399FailTimeoutAvion(state, action);
        case 13: // Police
          return part499FailTimeoutPolice(state, action);
      }
      return state;
  }
  return state
}

export default gameFlowColdGame