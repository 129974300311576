import browserHistory from 'discapecommon/browserHistory'
import store from '../store'
import { viewMission } from '../actions/missionActions'
import { userActions } from '../actions/userActions'
import { joinSession, applyHome, openStaticLink } from 'discapecommon/actions/index'
import { openDatasheet } from 'discapecommon/actions/appActions'

class NavigationManager {
  constructor() {
    this.previousPathname = '/';

    browserHistory.listen(({ location }) => {
      this.handlePathname(location.pathname);
    })

    setTimeout(() => this.handlePathname(browserHistory.location.pathname), 10)
  }

  handlePathname(pathname) {
    console.log('nav change : ', pathname)
    if (pathname == this.previousPathname) {
      return;
    }
    this.previousPathname = pathname;

    if (pathname == '/') {
      store.dispatch(openStaticLink('STATIC_LINK_GO_HOME'))
    }
    else {
      let params = pathname.split('/').filter(value => value != '')

      let param2 = '';
      if (params.length > 1) {
        param2 = params[1];
      }
      console.log('params ', params)
      switch (params[0]) {
        case 'browse': store.dispatch(openStaticLink('MISSION_LIST')); break;
        case 'session': case 'play':
          if (store.getState().session?.id != param2) {
            param2 = param2.replace('#', '')
            store.dispatch(joinSession(param2));
          }
          break;
        case 'mission': case 'title': store.dispatch(viewMission(param2)); break;
        case 'password-reset': store.dispatch(userActions.verifyResetToken(param2)); break;
        case 'about': store.dispatch(openDatasheet('discapeAbout')); break;
        case 'profile': store.dispatch(openStaticLink('PROFILE')); break;
        default: console.error('NOT FOUND'); break;
      }
    }
  }

  push(pathname) {
    if (pathname == this.previousPathname || pathname == this.previousPathname + '#')
      return;
    else
      browserHistory.push(pathname)
  }
}

export default new NavigationManager();