import { socket } from '../networkManager';
import { IoError, IoTimeoutError } from './IoPromiseError'

/*
  Send a socketio request to the server under the key "action", promise a result and timeout after 5 seconds.
*/
export default function sendClientRequest(request) {
  return new Promise((resolve, reject) => {
    if(!request?.type) {
      reject('Request must have a type. Got undefined');
    }
    const timeout = setTimeout(() => reject(new IoTimeoutError(request.type)), 8000);
    if(window.debug) {
      console.log('Socket emit action ', request)
    }
    socket.emit('action', request, (response) => {      
      if (response?.ok) {
        resolve(response);
      } else {
        if(!response) {
          reject(new IoError(request.type, response));
          return false;
        }

        if(response.status == 401) {
          console.log('Error 401')
          window.location.reload();
        }

        reject(new Error(response?.message))
      }
      clearTimeout(timeout); 
    });
  })
}