import { fr } from '../localeData/fr'
import { en } from '../localeData/en'
import SIDE_MENU from './sideMenu'

export const LOCALES = {
  locale: 'fr',
  fr,
  en
}
export const INTL = {
  defaultLocale: 'fr',
  locale: 'fr',
  messages: fr,
} 

// Answers Separator: ","
// Possible answer types: "number, text, ..."

import { ENIGMAS } from './enigmas';

export default {
  locales:LOCALES,
  intl:INTL,
  enigmas:ENIGMAS,
  sideMenu:SIDE_MENU.default
}